import { addComment, getComments } from '../api/api';

export const GET_COMMENTS_STARTED = 'GET_COMMENTS_STARTED';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAILURE = 'GET_COMMENTS_FAILURE';

export const fetchComments = refundId => {
  return dispatch => {
    dispatch(fetchCommentsStarted());

    return getComments(refundId)
      .then(response => {
        dispatch(fetchCommentsSuccess(response));
      })
      .catch(error => {
        dispatch(fetchCommentsFailure(error));
      });
  };
};

const fetchCommentsStarted = () => ({
  type: GET_COMMENTS_STARTED,
});

const fetchCommentsSuccess = response => {
  const comments = response.data.data;

  return {
    type: GET_COMMENTS_SUCCESS,
    payload: comments,
  };
};

const fetchCommentsFailure = error => ({
  type: GET_COMMENTS_FAILURE,
  payload: error,
});

export const addCommentToBooking = (RefundId, internalComments, user) => {
  // TODO: Handle Catch
  return dispatch => {
    dispatch(fetchCommentsStarted());
    return addComment(RefundId, internalComments, user).then(() => {
      dispatch(fetchComments(RefundId));
    });
  };
};
