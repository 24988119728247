const COMMON_CONSTANTS = {
  CARD_VARIANTS: {
    DEFAULT: 'default',
    FIXED_WIDTH: 'fixedWidth',
  },
  IMAGE_RATIOS: {
    FIVE_BY_FOUR: '5x4',
    TWO_BY_ONE: '2x1',
  },
};

export default COMMON_CONSTANTS;
