import React, { useState, useEffect } from 'react';
import { Field, Form, reduxForm, submit } from 'redux-form';
import InputText from '@viking-eng/input-text';
import Select from '@viking-eng/select';
import { FaRedo, FaDownload } from 'react-icons/fa';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import MultiSelect from '../MultiSelect/MultiSelect';

import { receiveBookings, searchBookings } from '../../actions/bookingActions';
import './RefundTableFilters.scss';
import {
  typeOptions,
  agingOptions,
  sailDateOptions,
  needsReviewOptions,
  readinessOptions,
  currenciesOptions,
  statusOptions,
  triggerModalAttrs,
  refundReasonOptions,
  systemReasonOptions,
} from '../../util/constants';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import {
  getAcctNum,
  getAcctType,
  getAgingDateDays,
  changeApprovalStatusForCSV,
  getInvoiceNumber,
  getCurrency,
  getName,
  getNeedsReview,
  getSystemReasonExport,
  getCruiseID,
  getShip,
  getSailDate,
  getVoyageCount,
  getSource,
  getShortpayAllow,
  getBalDueDate,
  getCxlDate,
  getDaysFromDeparture,
  getCxlReason,
  getRefundReason,
  getReqBy,
  getReqStatus,
  getDaystoEmbarkation,
  getLocked,
  getReqDateForCSV,
  getSaleDateForCSV,
  getUpdDateForCSV,
  getCxlPenalty,
  getExportRefund,
  getMethod,
} from '../RefundTable/RefundTableValues';
import { exportRecordsToCsv } from '../../util/common';

const RefundTableFilters = ({
  bookings,
  receiveBookings,
  loading,
  lastBookingRefreshDate,
  handleSubmit,
}) => {
  const [supportError, showSupportError] = useState(false);
  const toggleSupportError = () => {
    showSupportError(!supportError);
  };

  const header = [
    'Record Status',
    'Needs Review',
    'Invoice',
    'Currency',
    'Days to Embark',
    'Req Date',
    'Age Date',
    'Name',
    'Type',
    'Account No',
    'Transaction Method',
    'Total Refund',
    'Paid to Date',
    'Gross Price',
    'Gross Outstanding',
    'System Reason',
    'Booking Status',
    'Cruise ID',
    'Ship',
    'Sail Date',
    'Voyage Count',
    'Source',
    'Shortpay Allow',
    'Original Sale date',
    'Balance Due Date',
    'Cancel Date',
    'Cxl Days from Departure',
    'Cxl Penalty %',
    'Cxl Reason',
    'Commission',
    'Net Outstanding',
    'Requested Refund Total',
    'Net Refund Amount',
    'Discount Refund Amount',
    'Refund Reason',
    'Requested By',
    'Updated Date',
    'Refund Request Status',
    'Locked',
  ];

  const rows = bookings => [
    header,
    ...bookings.map(booking => [
      changeApprovalStatusForCSV(booking),
      getNeedsReview(booking) ? 'Yes' : 'No',
      getInvoiceNumber(booking),
      getCurrency(booking),
      `${getDaystoEmbarkation(booking)} days`,
      getReqDateForCSV(booking),
      getAgingDateDays(booking),
      getName(booking).split(',')[0],
      getAcctType(booking),
      getAcctNum(booking),
      getMethod(booking),
      getExportRefund(booking),
      booking.RecAmt,
      booking.GrossPrice,
      booking.Gross_Outstanding,
      getSystemReasonExport(booking) || '',
      booking.BookingStatusText,
      getCruiseID(booking),
      getShip(booking),
      getSailDate(booking),
      getVoyageCount(booking),
      getSource(booking),
      getShortpayAllow(booking),
      getSaleDateForCSV(booking),
      getBalDueDate(booking),
      getCxlDate(booking),
      getDaysFromDeparture(booking),
      getCxlPenalty(booking),
      getCxlReason(booking),
      booking.BookingStatus === 'K'
        ? booking.TotalCancelCommission
        : booking.TotalCommission,
      booking.Net_Outstanding,
      getExportRefund(booking),
      getExportRefund(booking),
      booking.DiscAmount,
      getRefundReason(booking),
      getReqBy(booking),
      getUpdDateForCSV(booking),
      getReqStatus(booking),
      getLocked(booking),
    ]),
  ];

  const handleExportToCsv = () => {
    const exportRows = rows(bookings);
    return exportRecordsToCsv(bookings, exportRows, toggleSupportError);
  };

  useEffect(() => {
    setTimeout(() => {
      showSupportError(false);
    }, 5000);
  }, [supportError]);

  return (
    <div className="filters-container">
      <Form onSubmit={handleSubmit} className="table-filters">
        <div className="search-input-container">
          <Field
            name="aging"
            component={MultiSelect}
            options={agingOptions}
            placeholder="Age Date"
            isSearchable={false}
            isMulti={true}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
          />
          <div style={{ flexGrow: 3 }}>
            <Field
              name="sailDate"
              component={MultiSelect}
              options={sailDateOptions}
              placeholder="Sail Date"
              isSearchable={false}
              isMulti
            />
          </div>
          <Field
            name="readiness"
            component={Select}
            options={readinessOptions}
            placeholder="Good to Go"
            isSearchable={false}
          />
          <Field
            name="currency"
            component={Select}
            options={currenciesOptions}
            placeholder="Currency"
            isSearchable={false}
          />
          <Field
            name="type"
            component={Select}
            options={typeOptions}
            placeholder="Type"
            isSearchable={false}
          />
          <Field
            name="booking_status"
            component={MultiSelect}
            options={statusOptions}
            placeholder="Status"
            isSearchable={false}
            isMulti
          />
        </div>
        <div className="search-input-container">
          <Field
            name="bookingNumber"
            component={InputText}
            placeholder="CruiseID/Invoice/Name"
          />
          <div style={{ flexGrow: 3 }}>
            <Field
              name="needsSupervisor"
              component={Select}
              options={needsReviewOptions}
              placeholder="Supervisor Review"
              isSearchable={false}
            />
          </div>
          <Field
            name="refundReason"
            component={MultiSelect}
            options={refundReasonOptions}
            placeholder="Refund Reason"
            isSearchable={false}
          />
          <Field
            name="systemReason"
            component={MultiSelect}
            options={systemReasonOptions}
            placeholder="System Reason"
            isSearchable={false}
          />
        </div>
        {/* TODO quick workaround to make form submission when user hits enter button */}
        <button type="submit" style={{ display: 'none' }}>
          Submit
        </button>
      </Form>

      <div className="button-container">
        <div className="buttons-only">
          {bookings.length > 0 && (
            <button className="filter-button" onClick={handleExportToCsv}>
              <FaDownload />
              <div
                className={classNames({
                  'filter-button-alert': true,
                  show: supportError,
                })}
              >
                Please update your browser to newer version or use another one
                to be able to export CSV.
              </div>
            </button>
          )}
          <button
            className={classNames('filter-button', {
              'spin-animation': loading,
            })}
            {...triggerModalAttrs}
          >
            <FaRedo />
          </button>
        </div>
        {lastBookingRefreshDate && (
          <div className="results-msg">
            Last Updated:
            <br />
            {moment(lastBookingRefreshDate).format('MM/DD/YYYY - LT')}
          </div>
        )}
      </div>
      <ConfirmModal
        id="confirmRefreshModal"
        onConfirm={() => receiveBookings()}
        title="Bookings Refresh"
        subtitle="Are you sure?"
      />
    </div>
  );
};

const mapStateToProps = state => ({
  allBookings: state.bookings.allBookings,
  bookings: state.bookings.bookings,
  loading: state.bookings.loading,
  lastBookingRefreshDate: state.bookings.receivedDate,
});

const mapDispatchToProps = dispatch => ({
  receiveBookings: () =>
    dispatch(receiveBookings()).then(() => {
      dispatch(submit('topPageFiltersForm'));
    }),
});

const onSubmit = (
  {
    bookingNumber,
    type,
    readiness,
    currency,
    aging,
    sailDate,
    needsSupervisor,
    booking_status,
    refundReason,
    systemReason,
  },
  dispatch,
  { allBookings }
) => {
  return dispatch(
    searchBookings(allBookings, {
      bookingNumber,
      type,
      readiness,
      currency,
      aging,
      sailDate,
      needsSupervisor,
      booking_status,
      refundReason,
      systemReason,
    })
  );
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'topPageFiltersForm',
    onSubmit,
    onChange: onSubmit,
  })
);

export default enhance(RefundTableFilters);
