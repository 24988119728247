import React from 'react';
import { connect } from 'react-redux';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import Loader from 'react-loader-spinner';

import {
  getRecordStatus,
  getInvoice,
  getCurrency,
  getName,
  getMessage,
  getProcessedDate,
  getAccountNum,
  getAcctType,
  getSource,
  getProcessedBy,
  getAmount,
  getBookingStatus,
  getCruiseID,
  getSailDate,
  getSalesCCID,
} from './ErrorTableValues';
import { ERROR_HEADERS, ERROR_SORT_POSITION } from '../../util/constants';
import { sortErrors } from '../../actions/errorActions';

import './ErrorTable.scss';

const ErrorTable = ({ errors, sortDirection, loading, handleSort }) => {
  const getSortAttrs = (header, position) => ({
    onClick: () => handleSort(errors, header, position, sortDirection),
    className: 'clickable',
  });

  const sortIcon = position =>
    sortDirection[position] === 1 ? <FaCaretDown /> : <FaCaretUp />;

  return (
    <div className="error-table">
      {errors && errors.length ? (
        <table className="table table-hover">
          <thead>
            <tr>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.RECORD_STATUS,
                  ERROR_SORT_POSITION.RECORD_STATUS
                )}
              >
                Status {sortIcon(ERROR_SORT_POSITION.RECORD_STATUS)}
              </td>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.INVOICE,
                  ERROR_SORT_POSITION.INVOICE
                )}
              >
                Invoice {sortIcon(ERROR_SORT_POSITION.INVOICE)}
              </td>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.CURRENCY,
                  ERROR_SORT_POSITION.CURRENCY
                )}
              >
                Currency {sortIcon(ERROR_SORT_POSITION.CURRENCY)}
              </td>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.SALES_CC_ID,
                  ERROR_SORT_POSITION.SALES_CC_ID
                )}
              >
                Sales CC ID {sortIcon(ERROR_SORT_POSITION.SALES_CC_ID)}
              </td>
              <td
                {...getSortAttrs(ERROR_HEADERS.NAME, ERROR_SORT_POSITION.NAME)}
              >
                Name {sortIcon(ERROR_SORT_POSITION.NAME)}
              </td>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.ACCT_TYPE,
                  ERROR_SORT_POSITION.ACCT_TYPE
                )}
              >
                Type {sortIcon(ERROR_SORT_POSITION.ACCT_TYPE)}
              </td>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.ACCT_NUM,
                  ERROR_SORT_POSITION.ACCT_NUM
                )}
              >
                Acct No {sortIcon(ERROR_SORT_POSITION.ACCT_NUM)}
              </td>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.AMOUNT,
                  ERROR_SORT_POSITION.AMOUNT
                )}
              >
                Amount {sortIcon(ERROR_SORT_POSITION.AMOUNT)}
              </td>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.PROCESSED_BY,
                  ERROR_SORT_POSITION.PROCESSED_BY
                )}
              >
                Processed By {sortIcon(ERROR_SORT_POSITION.PROCESSED_BY)}
              </td>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.PROCESSED_DATE,
                  ERROR_SORT_POSITION.PROCESSED_DATE
                )}
              >
                Processed Date {sortIcon(ERROR_SORT_POSITION.PROCESSED_DATE)}
              </td>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.CRUISE_ID,
                  ERROR_SORT_POSITION.CRUISE_ID
                )}
              >
                Cruise ID {sortIcon(ERROR_SORT_POSITION.CRUISE_ID)}
              </td>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.SAIL_DATE,
                  ERROR_SORT_POSITION.SAIL_DATE
                )}
              >
                Sail Date {sortIcon(ERROR_SORT_POSITION.SAIL_DATE)}
              </td>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.BOOKING_STATUS,
                  ERROR_SORT_POSITION.BOOKING_STATUS
                )}
              >
                Booking Status {sortIcon(ERROR_SORT_POSITION.BOOKING_STATUS)}
              </td>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.SOURCE,
                  ERROR_SORT_POSITION.SOURCE
                )}
              >
                Source {sortIcon(ERROR_SORT_POSITION.SOURCE)}
              </td>
              <td
                {...getSortAttrs(
                  ERROR_HEADERS.MESSAGE,
                  ERROR_SORT_POSITION.MESSAGE
                )}
              >
                Message {sortIcon(ERROR_SORT_POSITION.MESSAGE)}
              </td>
            </tr>
          </thead>
          <tbody>
            {errors.map(error => (
              <tr key={error.salesccid}>
                <td>{getRecordStatus(error)}</td>
                <td>{getInvoice(error)}</td>
                <td>{getCurrency(error)}</td>
                <td>{getSalesCCID(error)}</td>
                <td>{getName(error)}</td>
                <td>{getAcctType(error)}</td>
                <td>{getAccountNum(error)}</td>
                <td>{getAmount(error)}</td>
                <td>{getProcessedBy(error)}</td>
                <td>{getProcessedDate(error)}</td>
                <td>{getCruiseID(error)}</td>
                <td>{getSailDate(error)}</td>
                <td>{getBookingStatus(error)}</td>
                <td>{getSource(error)}</td>
                <td>{getMessage(error)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>
          {loading === true ? (
            <div className="loader-container">
              <Loader type="Oval" color="#456580" width="50" height="50" />
            </div>
          ) : (
            <h5>No results found</h5>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.errors.loading,
  errors: state.errors.errors,
  sortDirection: state.errors.sortDirection,
});

const mapDistpatchToProps = dispatch => ({
  handleSort: (errors, header, position, sortDirection) => {
    dispatch(sortErrors(errors, header, position, sortDirection));
  },
});

export default connect(mapStateToProps, mapDistpatchToProps)(ErrorTable);
