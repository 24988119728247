import moment from 'moment';

import {
  PAGE_NAME_FROM_PATH,
  BOOKING_STATUS,
  BOOKING_PROPERTIES,
  APPROVAL_STATUS,
} from './constants';
import {
  getName,
  getReqDate,
  getAcctType,
  getAcctNum,
  getDaysFromDeparture,
  getAgingDateDays,
} from '../components/RefundTable/RefundTableValues';

const { AMT, TOTAL_RFD_AMT, CCACHID, REFUND_ID, REF_TYPE } = BOOKING_PROPERTIES;

const {
  CHECK_ECK_DISCOUNT,
  UNSETTLED_ACH,
  UNSETTLED_CC,
  NEEDS_SUP_REVIEW,
  CHECK_COMMISSION,
  DIFFERENT_LAST_NAME,
  DIFFERENT_ADDRESS,
  GUEST_CXL_EXTENSION,
  CXL_BOOKING_UNBALANCED,
  SHORTPAY_ALLOW,
} = BOOKING_STATUS;

const { APPROVED } = APPROVAL_STATUS;

export const findBookingByRefundId = (RefundId, bookings) =>
  bookings.find(booking => booking.RefundId === RefundId);

export const formatNumber = val =>
  typeof val === 'number'
    ? val.toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : null;
export const formatDate = date => moment.utc(date).format('MM/DD/YYYY');
export const formatDateTime = date =>
  moment.utc(date).format('MM/DD/YYYY hh:mm A', true);
export const formatCommentDateTime = date =>
  moment(date).format('MM/DD/YYYY hh:mm A', true);
export const isUndefinedOrNull = val =>
  typeof val === 'undefined' || val === null;

export const isBookingGroup = booking => booking.Refunds.length > 1;

export const valueIsEqualToFirstFromArray = (val, i, arr) => val === arr[0];

export const getRefundAmt = (booking, bookingStatus) =>
  bookingStatus === 'K' || booking.BookingStatus === 'K'
    ? getValueBasedOnObject(booking, AMT)
    : getValueBasedOnObject(booking, TOTAL_RFD_AMT);

export const getGroupRefAmt = booking =>
  booking.Refunds.reduce((sum, r) => {
    const refundAmt =
      booking.BookingStatus === 'K'
        ? Math.abs(r.Amount) * -1
        : Math.abs(r.TotalRefundAmount) * -1;
    return sum + refundAmt;
  }, 0);

export const getValuesFromArrayAndCheckIfTheyAreEqual = (
  arrayToExtractFrom = [],
  functionToGetProperty
) => {
  return arrayToExtractFrom
    .map(element => functionToGetProperty(element))
    .every(valueIsEqualToFirstFromArray);
};

export const getValueBasedOnObject = (mainObject, property) =>
  mainObject.Refunds ? mainObject.Refunds[0][property] : mainObject[property];
export const reqDatesAreEqual = entries =>
  getValuesFromArrayAndCheckIfTheyAreEqual(entries, getReqDate);
export const namesAreEqual = entries =>
  getValuesFromArrayAndCheckIfTheyAreEqual(entries, getName);
export const accountTypesAreEqual = entries =>
  getValuesFromArrayAndCheckIfTheyAreEqual(entries, getAcctType);
export const accountNumsAreEqual = entries =>
  getValuesFromArrayAndCheckIfTheyAreEqual(entries, getAcctNum);
export const agingDatesAreEqual = entries =>
  getValuesFromArrayAndCheckIfTheyAreEqual(entries, getAgingDateDays);

export const groupedBookingNamesAreEqual = booking =>
  getValuesFromArrayAndCheckIfTheyAreEqual(booking, getName);

export const resetOnAuthSuccessRedirectPath = () => {
  const secureRouterReferrerPath = JSON.parse(
    localStorage.getItem('secureRouterReferrerPath')
  );
  secureRouterReferrerPath['pathname'] = '/';
  localStorage.setItem(
    'secureRouterReferrerPath',
    JSON.stringify(secureRouterReferrerPath)
  );
};

export const exportRecordsToCsv = (
  recordsToExport,
  rows,
  handleSupportError
) => {
  const csvContent = rows.map(e => e.join(',')).join('\n');
  const pageName = PAGE_NAME_FROM_PATH[window.location.pathname];
  const dateTime = moment().format('MM.DD.YYYY_h꞉mm_A');
  const filename = `ARA_${pageName}_${dateTime}.csv`;
  const blob = new Blob([csvContent], {
    type: 'text/csv;sep=,charset=utf-8;',
  });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    // eslint-disable-next-line
    if (typeof link.download !== undefined) {
      // feature detection, Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.setAttribute('style', 'display: none;');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      handleSupportError();
    }
  }
};

export const checkSystemReasons = booking => {
  const systemReasons = [];
  const bookingStatus = booking.BookingStatus;
  const firstRefund = booking.Refunds[0];
  const refundAmt = Math.abs(getRefundAmt(firstRefund, bookingStatus)) * -1;

  const roundedRefundAmt = parseFloat(
    (Math.round(refundAmt * 100) / 100).toFixed(2)
  );
  if (booking.HasACH && getDaysFromDeparture(booking) <= 30) {
    systemReasons.push(CHECK_ECK_DISCOUNT);
  }
  if (booking.UnsettledCC === true) {
    systemReasons.push(UNSETTLED_CC);
  }
  if (booking.UnsettledACH === true) {
    systemReasons.push(UNSETTLED_ACH);
  }
  if (!isUndefinedOrNull(booking.markedNeedsReview)) {
    systemReasons.push(NEEDS_SUP_REVIEW);
  }
  if (!booking.DirectFlag && getDaysFromDeparture(booking) <= 30) {
    systemReasons.push(CHECK_COMMISSION);
  }
  if (booking.NameMatch === 'N') {
    systemReasons.push(DIFFERENT_LAST_NAME);
  }
  if (booking.AddressMatch === 'N') {
    systemReasons.push(DIFFERENT_ADDRESS);
  }
  if (booking.GuestCancelExtension === 'Y') {
    systemReasons.push(GUEST_CXL_EXTENSION);
  }
  if (booking.Refunds.length > 1) {
    const bookingGroupTotalRefund = booking.Refunds.reduce((sum, r) => {
      let refundAmt = getRefundAmt(r, bookingStatus);
      if (refundAmt < 0) {
        refundAmt *= -1;
      }
      return sum + refundAmt;
    }, 0);

    const roundedTotalRefund = parseFloat(
      (Math.round(bookingGroupTotalRefund * 100) / 100).toFixed(2)
    );

    if (roundedTotalRefund !== booking.Gross_Outstanding * -1) {
      systemReasons.push(CXL_BOOKING_UNBALANCED);
    }
  } else if (roundedRefundAmt !== booking.Gross_Outstanding) {
    systemReasons.push(CXL_BOOKING_UNBALANCED);
  }
  if (
    (booking.Currency === 'USD' || booking.Currency === 'CAD') &&
    booking.ShortPayAllow
  ) {
    systemReasons.push(SHORTPAY_ALLOW);
  }

  return systemReasons;
};

export const getApprovedBookings = bookings =>
  bookings.filter(booking => booking.approvalStatus === APPROVED);
export const mapBookingsToSubmit = (refund, booking = refund) => ({
  Id: getValueBasedOnObject(refund, CCACHID),
  RefundId: getValueBasedOnObject(refund, REFUND_ID),
  Invoice: booking.Invoice,
  Amount: Math.abs(getValueBasedOnObject(refund, AMT)) * -1,
  GrossAmount: booking.GrossPrice,
  RequestType: getValueBasedOnObject(refund, REF_TYPE),
  RecAmount: booking.RecAmt,
});

export const getBookingsToSubmit = bookings => {
  const bookingsToSubmit = [];
  // eslint-disable-next-line array-callback-return
  bookings.map(booking => {
    if (isBookingGroup(booking)) {
      booking.Refunds.forEach(refund =>
        bookingsToSubmit.push(mapBookingsToSubmit(refund, booking))
      );
    } else {
      bookingsToSubmit.push(mapBookingsToSubmit(booking));
    }
  });
  return bookingsToSubmit;
};
