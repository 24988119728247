import { formatNumber, formatDate, formatDateTime } from '../../util/common';

export const getRecordStatus = error => error.Status;
export const getInvoice = error => error.Invoice;
export const getCurrency = error => error.Currency;
export const getName = error =>
  `${error.FirstName.toUpperCase()} ${error.LastName.toUpperCase()}`;
export const getMessage = error => error.Message;
export const getProcessedDate = error => formatDate(error.EntDate);
export const getProcessedDateForCSV = error => formatDateTime(error.EntDate);
export const getAccountNum = error => error.AccountNumber.slice(-6);
export const getAcctType = error => error.AccountType;
export const getSource = error => error.Source;
export const getProcessedBy = error => error.UserId;
export const getBookingStatus = error => error.StatusText;
export const getCruiseID = error => error.Cruise;
export const getSailDate = error => formatDate(error.SailDate);
export const getAmount = error => `$ ${formatNumber(error.Amount * -1)}`;
export const getSalesCCID = error => error.SalesCreditCardId;
