import React, { useState, useEffect } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import InputText from '@viking-eng/input-text';
import Select from '@viking-eng/select';

import { FaDownload } from 'react-icons/fa';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { searchHistories } from '../../actions/historyActions';
import './HistoryTableFilters.scss';
import MultiSelect from '../MultiSelect/MultiSelect';

import {
  historyProcessedDateOptions,
  processedByOptions,
  araProcessedOptions,
  bookingStatusOptions,
  refundReasonOptions,
  paymentTypeOptions,
} from '../../util/constants';
import { exportRecordsToCsv } from '../../util/common';
import {
  getRecordStatus,
  getInvoice,
  getCurrency,
  getReqDateForCSV,
  getProcessedDateForCSV,
  getAgeDate,
  getProcessedBy,
  getProcessedStatus,
  getSource,
  getBookingStatus,
  getCruiseID,
  getSailDate,
  getName,
  getAcctType,
  getAccountNum,
  getRefundReason,
  getUpdatedDateTime,
} from '../HistoryTable/HistoryTableValues';

const HistoryTableFilters = ({ allHistory, history, handleSubmit }) => {
  const [supportError, showSupportError] = useState(false);
  const toggleSupportError = () => {
    showSupportError(!supportError);
  };

  const header = [
    'Record Status',
    'Invoice',
    'Currency',
    'Req Date',
    'Processed Date',
    'Age Date',
    'Processed By',
    'ARA Processed',
    'Source',
    'Booking Status',
    'Cruise ID',
    'Sail Date',
    'Name',
    'Type',
    'Account No',
    'Total Refund',
    'Refund Reason',
    'Ship',
    'Updated Date',
  ];

  const rows = [
    header,
    ...(history !== null
      ? history.map(h => [
          getRecordStatus(h),
          getInvoice(h),
          getCurrency(h),
          getReqDateForCSV(h),
          getProcessedDateForCSV(h),
          getAgeDate(h),
          getProcessedBy(h),
          getProcessedStatus(h),
          getSource(h),
          getBookingStatus(h),
          getCruiseID(h),
          getSailDate(h),
          getName(h),
          getAcctType(h),
          getAccountNum(h),
          h.TotalRefundAmount,
          getRefundReason(h),
          h.Ship,
          getUpdatedDateTime(h),
        ])
      : []),
  ];

  const handleExportToCsv = () => {
    exportRecordsToCsv(history, rows, toggleSupportError);
  };

  useEffect(() => {
    setTimeout(() => {
      showSupportError(false);
    }, 5000);
  }, [supportError]);

  const processedByUsers =
    allHistory !== null
      ? allHistory.map(history => history.ModifiedUserId)
      : [];
  const processedBy =
    allHistory === [] ? '' : processedByOptions([...new Set(processedByUsers)]);

  return (
    <div className="history-filters-container">
      <Form onSubmit={handleSubmit} className="table-filters">
        <Field
          name="historyNumber"
          component={InputText}
          placeholder="CruiseID/Invoice/Name"
        />
        <Field
          name="processedDate"
          component={MultiSelect}
          options={historyProcessedDateOptions}
          placeholder="Processed Date"
          isSearchable={false}
        />
        <Field
          name="processedBy"
          component={MultiSelect}
          options={processedBy}
          placeholder="Processed By"
          isSearchable={false}
        />
        <Field
          name="araProcessed"
          component={Select}
          options={araProcessedOptions}
          placeholder="ARA Processed"
          isSearchable={false}
        />
        <Field
          name="bookingStatus"
          component={Select}
          options={bookingStatusOptions}
          placeholder="Booking Status"
          isSearchable={false}
        />
        <Field
          name="refundReason"
          component={MultiSelect}
          options={refundReasonOptions}
          placeholder="Refund Reason"
          isSearchable={false}
        />
        <Field
          name="type"
          component={MultiSelect}
          options={paymentTypeOptions}
          placeholder="Type"
          isSearchable={false}
        />
        {/* TODO quick workaround to make form submission when user hits enter button */}
        <button type="submit" style={{ display: 'none' }}>
          Submit
        </button>
      </Form>

      {history && history.length > 0 && (
        <button className="filter-button" onClick={handleExportToCsv}>
          <FaDownload />
          <div
            className={classNames({
              'filter-button-alert': true,
              show: supportError,
            })}
          >
            Please update your browser to newer version or use another one to be
            able to export CSV.
          </div>
        </button>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  allHistory: state.history.allHistory,
  history: state.history.history,
  loading: state.history.loading,
});

const onSubmit = (
  {
    historyNumber,
    processedDate,
    processedBy,
    araProcessed,
    bookingStatus,
    refundReason,
    type,
  },
  dispatch,
  { allHistory }
) => {
  return dispatch(
    searchHistories(allHistory, {
      historyNumber,
      processedDate,
      processedBy,
      araProcessed,
      bookingStatus,
      refundReason,
      type,
    })
  );
};

const enhance = compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: 'historyPageFiltersForm',
    onSubmit,
    onChange: onSubmit,
  })
);

export default enhance(HistoryTableFilters);
