import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import {
  FaAngleDown,
  FaAngleUp,
  FaUserFriends,
  FaCheck,
  FaTimes,
  FaLock,
  FaLockOpen,
} from 'react-icons/fa';
import { MdComment } from 'react-icons/md';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import {
  getApprovalStatus,
  getName,
  getInvoiceNumber,
  getCurrency,
  getDaystoEmbarkation,
  getGroupRefund,
  getReqDate,
  getAcctType,
  getPaid,
  getGrossPrice,
  getGrossOutstanding,
  getSystemReason,
  getAcctNum,
  getAgingDateDays,
  getHasComments,
  getNeedsReview,
  getRefund,
  getInUseStatus,
} from '../RefundTableValues';
import {
  APPROVAL_STATUS,
  invoiceNumberModalAttrs,
  BOOKING_HEADERS,
} from '../../../util/constants';
import { getValuesFromArrayAndCheckIfTheyAreEqual } from '../../../util/common';

const { APPROVED, PENDING, DECLINED } = APPROVAL_STATUS;

const BookingGroup = ({
  multiRefundBooking,
  bookings,
  openBookingDetailsModal,
  handleGroupIconUpdate,
  getClickableIconAttrs,
  user,
  handleMultiClick,
}) => {
  const getClickableGroupIconAttrs = (
    header,
    bookings,
    invoice,
    color,
    user
  ) => ({
    header: { header },
    className: `clickable clickable-icon color-viking-${color}`,
    user: { user },
    onClick: () => handleGroupIconUpdate(header, bookings, invoice, user),
  });

  const allRefundsForBooking = multiRefundBooking.Refunds;

  const approvalStatus = getApprovalStatus(multiRefundBooking);
  const approvalStatusApproved = approvalStatus === APPROVED;
  const approvalStatusPendingOrApproved =
    approvalStatusApproved || approvalStatus === PENDING;

  const reqDates = allRefundsForBooking.map(refund =>
    moment(refund.RequestDate).startOf('day')
  );

  const reqDatesAreEqual = reqDates.every(date =>
    moment(date).isSame(reqDates[0])
  );

  const agingDatesAreEqual = getValuesFromArrayAndCheckIfTheyAreEqual(
    allRefundsForBooking,
    getAgingDateDays
  );
  const namesAreEqual = getValuesFromArrayAndCheckIfTheyAreEqual(
    allRefundsForBooking,
    getName
  );
  const acctTypesAreEqual = getValuesFromArrayAndCheckIfTheyAreEqual(
    allRefundsForBooking,
    getAcctType
  );
  const acctNumbersAreEqual = getValuesFromArrayAndCheckIfTheyAreEqual(
    allRefundsForBooking,
    getAcctNum
  );
  const totalRefund = getGroupRefund(
    multiRefundBooking.BookingStatus,
    allRefundsForBooking
  );
  const paidAmountsAreEqual = getValuesFromArrayAndCheckIfTheyAreEqual(
    allRefundsForBooking,
    getPaid
  );
  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <tr
        className={classNames({
          'grouped-summary-line': true,
          highlighted: approvalStatusPendingOrApproved,
        })}
        onClick={handleMultiClick}
      >
        {approvalStatusPendingOrApproved ? (
          approvalStatusApproved ? (
            <td
              className="clickable color-viking-green"
              onClick={() => {
                handleGroupIconUpdate(
                  BOOKING_HEADERS.APPROVAL_STATUS,
                  bookings,
                  multiRefundBooking.Invoice,
                  user
                );
              }}
              data-index={multiRefundBooking.index}
              data-column={APPROVED}
            >
              <FaCheck
                style={{ pointerEvents: 'none' }}
                data-column={APPROVED}
              />
            </td>
          ) : (
            <td
              className="clickable color-viking-gray"
              onClick={() => {
                handleGroupIconUpdate(
                  BOOKING_HEADERS.APPROVAL_STATUS,
                  bookings,
                  multiRefundBooking.Invoice,
                  user
                );
              }}
              data-index={multiRefundBooking.index}
              data-column={PENDING}
            >
              <FaCheck
                style={{ pointerEvents: 'none' }}
                data-column={PENDING}
              />
            </td>
          )
        ) : (
          <td
            className="clickable color-viking-red"
            onClick={() => {
              handleGroupIconUpdate(
                BOOKING_HEADERS.APPROVAL_STATUS,
                bookings,
                multiRefundBooking.Invoice,
                user
              );
            }}
            data-index={multiRefundBooking.index}
            data-column={DECLINED}
          >
            <FaTimes style={{ pointerEvents: 'none' }} data-column={DECLINED} />
          </td>
        )}
        <td
          className={`color-viking-${
            getNeedsReview(multiRefundBooking) ? 'red' : 'gray'
          }`}
          onClick={() => {
            handleGroupIconUpdate(
              BOOKING_HEADERS.REVIEW,
              bookings,
              multiRefundBooking.Invoice,
              user
            );
          }}
        >
          <FaUserFriends />
        </td>
        <td>{multiRefundBooking.comments && <MdComment />}</td>
        <td>{getInvoiceNumber(multiRefundBooking)}</td>
        <td>{getCurrency(multiRefundBooking)}</td>
        <td>{getDaystoEmbarkation(multiRefundBooking)} days</td>
        <td>{reqDatesAreEqual && getReqDate(multiRefundBooking)}</td>
        <td>
          {agingDatesAreEqual && `${getAgingDateDays(multiRefundBooking)} days`}
        </td>
        <td className="name-cell">
          {namesAreEqual && getName(multiRefundBooking)}
        </td>
        <td>{acctTypesAreEqual && getAcctType(multiRefundBooking)}</td>
        <td>{acctNumbersAreEqual && getAcctNum(multiRefundBooking)}</td>
        <td className="clickable money-col negative" onClick={handleExpand}>
          {!expanded ? (
            <FaAngleDown className="expand-icon" />
          ) : (
            <FaAngleUp className="expand-icon" />
          )}
          {totalRefund}
        </td>
        <td className="money-col">
          {paidAmountsAreEqual && getPaid(multiRefundBooking)}
        </td>
        <td className="money-col">{getGrossPrice(multiRefundBooking)}</td>
        <td className="money-col negative">
          {getGrossOutstanding(multiRefundBooking)}
        </td>
        <td>{getSystemReason(multiRefundBooking)}</td>
        <td
          className={classNames({
            clickable: true,
            [`color-viking-${getInUseStatus(multiRefundBooking, user)}`]: true,
          })}
          onClick={() =>
            handleGroupIconUpdate(
              BOOKING_HEADERS.IN_USE,
              bookings,
              multiRefundBooking.Invoice,
              user
            )
          }
          data-column={BOOKING_HEADERS.IN_USE}
          data-user={multiRefundBooking.inUseBy}
          data-index={multiRefundBooking.index}
        >
          {multiRefundBooking.inUseBy ? (
            <div
              className="lock"
              data-tip={multiRefundBooking.inUseBy}
              data-column={BOOKING_HEADERS.IN_USE}
              data-user={multiRefundBooking.inUseBy}
              data-index={multiRefundBooking.index}
            >
              <FaLock style={{ pointerEvents: 'none' }} />
              <ReactTooltip
                className="locktext"
                place="top"
                type="dark"
                effect="solid"
              />
            </div>
          ) : (
            <FaLockOpen
              style={{ pointerEvents: 'none' }}
              data-column={BOOKING_HEADERS.IN_USE}
              data-user={multiRefundBooking.inUseBy}
            />
          )}
        </td>
      </tr>
      {allRefundsForBooking.map(refund => (
        <tr
          key={refund.RefundId}
          className={classNames({
            'single-booking-entry': true,
            hidden: !expanded,
            highlighted:
              refund.approvalStatus === APPROVED ||
              refund.approvalStatus === PENDING,
          })}
          onClick={handleMultiClick}
        >
          {(() => {
            switch (getApprovalStatus(multiRefundBooking)) {
              case APPROVED:
                return (
                  <td
                    {...getClickableGroupIconAttrs(
                      BOOKING_HEADERS.APPROVAL_STATUS,
                      bookings,
                      multiRefundBooking.Invoice,
                      'green',
                      user
                    )}
                    data-index={multiRefundBooking.index}
                    data-column={APPROVED}
                  >
                    <FaCheck
                      styles={{ pointerEvents: 'none' }}
                      data-column={APPROVED}
                    />
                  </td>
                );
              case DECLINED:
                return (
                  <td
                    {...getClickableGroupIconAttrs(
                      BOOKING_HEADERS.APPROVAL_STATUS,
                      bookings,
                      multiRefundBooking.Invoice,
                      'red',
                      user
                    )}
                    data-index={multiRefundBooking.index}
                    data-column={DECLINED}
                  >
                    <FaTimes
                      style={{ pointerEvents: 'none' }}
                      data-column={DECLINED}
                    />
                  </td>
                );
              default:
                return (
                  <td
                    {...getClickableGroupIconAttrs(
                      BOOKING_HEADERS.APPROVAL_STATUS,
                      bookings,
                      multiRefundBooking.Invoice,
                      'gray',
                      user
                    )}
                    data-index={multiRefundBooking.index}
                    data-column={PENDING}
                  >
                    <FaCheck
                      style={{ pointerEvents: 'none' }}
                      data-column={PENDING}
                    />
                  </td>
                );
            }
          })()}
          <td
            {...getClickableIconAttrs(
              BOOKING_HEADERS.REVIEW,
              bookings,
              refund.RefundId,
              getNeedsReview(refund) ? 'red' : 'gray',
              user
            )}
          >
            <FaUserFriends />
          </td>
          <td>
            {getHasComments(refund) && (
              <MdComment
                onClick={() => {
                  openBookingDetailsModal(refund, multiRefundBooking);
                }}
                {...invoiceNumberModalAttrs}
              />
            )}
          </td>
          <td
            className="clickable"
            onClick={() => {
              openBookingDetailsModal(refund, multiRefundBooking);
            }}
            {...invoiceNumberModalAttrs}
          >
            {getInvoiceNumber(refund)}
          </td>
          <td>{getCurrency(multiRefundBooking)}</td>
          <td>{getDaystoEmbarkation(multiRefundBooking)} days</td>
          <td>{getReqDate(refund)}</td>
          <td>{getAgingDateDays(refund)} days</td>
          <td className="name-cell">{getName(refund)}</td>
          <td>{getAcctType(refund)}</td>
          <td>{getAcctNum(refund)}</td>
          <td className="money-col negative">{getRefund(refund)}</td>
          <td className="money-col">{getPaid(multiRefundBooking)}</td>
          <td className="money-col">{getGrossPrice(multiRefundBooking)}</td>
          <td className="money-col negative">
            {getGrossOutstanding(multiRefundBooking)}
          </td>
          <td>{getSystemReason(multiRefundBooking)}</td>
          <td
            className={classNames({
              clickable: true,
              [`color-viking-${getInUseStatus(
                multiRefundBooking,
                user
              )}`]: true,
            })}
            onClick={() =>
              handleGroupIconUpdate(
                BOOKING_HEADERS.IN_USE,
                bookings,
                multiRefundBooking.Invoice,
                user
              )
            }
            data-column={BOOKING_HEADERS.IN_USE}
            data-user={multiRefundBooking.inUseBy}
            data-index={multiRefundBooking.index}
          >
            {multiRefundBooking.inUseBy ? (
              <div
                className="lock"
                data-tip={multiRefundBooking.inUseBy}
                data-column={BOOKING_HEADERS.IN_USE}
                data-user={multiRefundBooking.inUseBy}
                data-index={multiRefundBooking.index}
              >
                <FaLock style={{ pointerEvents: 'none' }} />
                <ReactTooltip
                  className="locktext"
                  place="top"
                  type="dark"
                  effect="solid"
                />
              </div>
            ) : (
              <FaLockOpen
                style={{ pointerEvents: 'none' }}
                data-column={BOOKING_HEADERS.IN_USE}
                data-user={multiRefundBooking.inUseBy}
              />
            )}
          </td>
        </tr>
      ))}
    </>
  );
};

export default BookingGroup;
