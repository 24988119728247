import moment from 'moment';

import { getErrors } from '../api/api';
import { ERROR_HEADERS, ERROR_PROCESSED_DATE_OPTIONS } from '../util/constants';
import { isUndefinedOrNull } from '../util/common';

export const GET_ERRORS_STARTED = 'GET_ERRORS_STARTED';
export const GET_ERRORS_SUCCESS = 'GET_ERRORS_SUCCESS';
export const GET_ERRORS_FAILURE = 'GET_ERRORS_FAILURE';

export const receiveErrors = () => {
  return dispatch => {
    dispatch(receiveErrorsStarted());

    return getErrors()
      .then(response => {
        dispatch(receiveErrorsSuccess(response));
      })
      .catch(error => {
        dispatch(receiveErrorsFailure(error));
      });
  };
};

const receiveErrorsStarted = () => ({
  type: GET_ERRORS_STARTED,
  loading: true,
  payload: [],
});

const receiveErrorsSuccess = response => {
  const errors = response.data.data.sort(
    (a, b) => parseInt(a.Invoice) - parseInt(b.Invoice)
  );

  return {
    type: GET_ERRORS_SUCCESS,
    payload: errors,
  };
};

const receiveErrorsFailure = error => ({
  type: GET_ERRORS_FAILURE,
  payload: error,
});

export const searchErrors = (
  errors,
  { processedDate, processedBy, bookingStatus, type }
) => {
  let foundErrors = errors;

  if (!isUndefinedOrNull(processedDate) && processedDate.length > 0) {
    let dateFilter;
    let filteredErrors,
      multiFilteredErrors = [];

    processedDate.forEach(option => {
      let value = option.value;
      if (value === ERROR_PROCESSED_DATE_OPTIONS.TODAY) {
        dateFilter = daysCount => daysCount <= 1;
      }
      if (value === ERROR_PROCESSED_DATE_OPTIONS.LAST_WEEK) {
        dateFilter = daysCount => daysCount > 1 && daysCount <= 7;
      }
      if (value === ERROR_PROCESSED_DATE_OPTIONS.TWO_WEEKS) {
        dateFilter = daysCount => daysCount > 7 && daysCount <= 14;
      }
      if (value === ERROR_PROCESSED_DATE_OPTIONS.LAST_MONTH) {
        dateFilter = daysCount => daysCount > 14 && daysCount <= 30;
      }
      filteredErrors = foundErrors = foundErrors.filter(({ EntDate }) =>
        dateFilter(moment().diff(EntDate, 'days', true))
      );
      multiFilteredErrors = multiFilteredErrors.concat(filteredErrors);
    });
    foundErrors = multiFilteredErrors;
  }

  if (!isUndefinedOrNull(processedBy) && processedBy.length > 0) {
    let filteredErrors,
      multiFilteredErrors = [];
    processedBy.forEach(option => {
      filteredErrors = foundErrors.filter(
        ({ UserId }) => UserId === option.value
      );
      multiFilteredErrors = multiFilteredErrors.concat(filteredErrors);
    });
    foundErrors = multiFilteredErrors;
  }

  if (!isUndefinedOrNull(bookingStatus)) {
    foundErrors = foundErrors.filter(({ StatusText }) => {
      return StatusText === bookingStatus;
    });
  }

  if (!isUndefinedOrNull(type) && type.length > 0) {
    let filteredErrors,
      multiFilteredErrors = [];

    type.forEach(option => {
      filteredErrors = foundErrors.filter(
        ({ AccountType }) => AccountType === option.value
      );
      multiFilteredErrors = multiFilteredErrors.concat(filteredErrors);
    });
    foundErrors = multiFilteredErrors;
  }

  return {
    type: 'SEARCH_ERRORS',
    payload: foundErrors,
  };
};

export const sortErrors = (bookings, header, position, direction) => {
  let sortedErrors = [...bookings];
  direction = direction.map((colDirection, index) => {
    if (index === position) {
      return colDirection === -1 ? 1 : -1;
    }
    return 1;
  });

  if (direction[position] === -1) {
    let filter;
    switch (header) {
      case ERROR_HEADERS.RECORD_STATUS:
        filter = (a, b) => a.Status.localeCompare(b.Status);
        break;
      case ERROR_HEADERS.INVOICE:
        filter = (a, b) => parseInt(a.Invoice) - parseInt(b.Invoice);
        break;
      case ERROR_HEADERS.CURRENCY:
        filter = (a, b) => a.Currency.localeCompare(b.Currency);
        break;
      case ERROR_HEADERS.SALES_CC_ID:
        filter = (a, b) =>
          parseInt(a.SalesCreditCardId) - parseInt(b.SalesCreditCardId);
        break;
      case ERROR_HEADERS.PROCESSED_DATE:
        filter = (a, b) =>
          moment(a.EntDate).unix() >= moment(b.EntDate).unix() ? 1 : -1;
        break;
      case ERROR_HEADERS.SAIL_DATE:
        filter = (a, b) =>
          moment(a.SailDate).unix() >= moment(b.SailDate).unix() ? 1 : -1;
        break;
      case ERROR_HEADERS.NAME:
        filter = (a, b) => a.LastName.localeCompare(b.LastName);
        break;
      case ERROR_HEADERS.ACCT_TYPE:
        filter = (a, b) => a.AccountType.localeCompare(b.AccountType);
        break;
      case ERROR_HEADERS.ACCT_NUM:
        filter = (a, b) =>
          a.AccountNumber.slice(-6) >= b.AccountNumber.slice(-6) ? 1 : -1;
        break;
      case ERROR_HEADERS.PROCESSED_BY:
        filter = (a, b) => a.UserId.localeCompare(b.UserId);
        break;
      case ERROR_HEADERS.SOURCE:
        filter = (a, b) => a.Source.localeCompare(b.Source);
        break;
      case ERROR_HEADERS.BOOKING_STATUS:
        filter = (a, b) => a.StatusText.localeCompare(b.StatusText);
        break;
      case ERROR_HEADERS.CRUISE_ID:
        filter = (a, b) => a.Cruise.localeCompare(b.Cruise);
        break;
      case ERROR_HEADERS.AMOUNT:
        filter = (a, b) => parseInt(a.Amount) - parseInt(b.Amount);
        break;
      case ERROR_HEADERS.MESSAGE:
        filter = (a, b) => a.Message.localeCompare(b.Message);
        break;
      default:
        break;
    }
    sortedErrors = sortedErrors.sort(filter);
  } else if (direction[position] === 1) {
    sortedErrors = sortedErrors.reverse();
  }

  return {
    type: 'SORT_ERRORS',
    direction,
    payload: sortedErrors,
  };
};
