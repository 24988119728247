import React from 'react';
import Button from '@viking-eng/button';
import Modal from '@viking-eng/modal/lib/v2';
import * as PropTypes from 'prop-types';

import './ErrorModal.scss';

const ErrorModal = ({ id, confirmText, title, subtitle }) => {
  const onErrorModal = () => {
    window.$(`#${id}`).modal('hide');
  };

  return (
    <Modal id={id}>
      <div className="error-modal">
        <div className="content">
          <div className="row summary-row justify-content-center">
            <div className="col-sm">
              <div className="details-column">
                <h3>{title}</h3>
              </div>
            </div>
          </div>
          <div className="row summary-row justify-content-center top-spacing">
            <div className="col-sm">
              <div className="details-column">{subtitle}</div>
            </div>
          </div>
          <div className="row buttons-row justify-content-center">
            <Button
              className="hover-btn"
              appearance="secondary-blue"
              onButtonClick={onErrorModal}
            >
              {confirmText}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ErrorModal.propTypes = {
  id: PropTypes.string,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ErrorModal.defaultProps = {
  confirmText: 'OK',
  title: 'Error!',
  subtitle: '',
};

export default ErrorModal;
