import React from 'react';
import classNames from 'classnames';

export const renderItem = (
  label,
  value,
  redValueColor = false,
  boldLabel = false
) => (
  <div className="item justify-content-between">
    <span
      className={classNames('title', {
        'font-weight-bold': boldLabel,
      })}
    >
      {label}:
    </span>
    <span
      className={classNames('font-weight-bold', {
        'red-value': redValueColor,
      })}
    >
      {value}
    </span>
  </div>
);
