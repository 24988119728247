export const UPDATE_AUTH = 'UPDATE_AUTH';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const updateAuth = authenticated => ({
  type: UPDATE_AUTH,
  payload: authenticated,
});

export const loginUser = user => ({
  type: LOGIN,
  payload: {
    name: user.username,
    evolutionId: user.evolutionId,
    isSupervisor: user.isSupervisor,
  },
});

export const logoutUser = () => ({
  type: LOGOUT,
});
