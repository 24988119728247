import React from 'react';
import { components } from 'react-select';

const ValueContainer = ({ children, getValue, ...props }) => {
  var length = getValue().length;

  return (
    <components.ValueContainer {...props}>
      {length === 0 ? props.selectProps.placeholder : `${length} selected`}
      {React.cloneElement(children[1])}
    </components.ValueContainer>
  );
};

export default ValueContainer;
