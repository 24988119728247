import { UPDATE_AUTH, LOGIN, LOGOUT } from '../actions/authActions';

const authReducer = (
  state = {
    authenticated: false,
    user: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_AUTH:
      return { ...state, authenticated: action.payload };
    case LOGIN:
      return { authenticated: true, user: action.payload };
    case LOGOUT:
      return { authenticated: false, user: null };
    default:
      return state;
  }
};

export default authReducer;
