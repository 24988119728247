import React from 'react';
import { Field, Form, reduxForm, submit } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import { updateHistory } from '../../../actions/historyActions';
import './HistoryTableParams.scss';

const validate = values => {
  const errors = {};
  let refundProcessedStartDate = new Date(values.refundProcessedStartDate);
  let refundProcessedEndDate = new Date(values.refundProcessedEndDate);
  let refundRequestStartDate = new Date(values.refundRequestStartDate);
  let refundRequestEndDate = new Date(values.refundRequestEndDate);
  if (moment(refundProcessedEndDate).diff(refundProcessedStartDate) < 0) {
    errors.refundProcessedEndDate =
      'Please enter a date that is later than date from';
  }

  if (moment(refundRequestEndDate).diff(refundRequestStartDate) < 0) {
    errors.refundRequestEndDate =
      'Please enter a date that is later than date from';
  }
  return errors;
};

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label className="date-label">{label}</label>
    <input {...input} type={type} className="date-select" />
    <div>
      {touched && error && <span className="form-error-message">{error}</span>}
    </div>
  </div>
);

const HistoryTableParams = ({ handleSubmit, initialValues }) => {
  return (
    <div className="params-filters-container">
      <Form onSubmit={handleSubmit} className="table-filters">
        <Field
          name="refundProcessedStartDate"
          component={renderField}
          type="date"
          label="Process Start Date"
          placeholder={initialValues.refundProcessedStartDate}
        />
        <Field
          name="refundProcessedEndDate"
          component={renderField}
          type="date"
          label="Process End Date"
          placeholder={initialValues.refundProcessedEndDate}
        />
        <Field
          name="refundRequestStartDate"
          component={renderField}
          type="date"
          label="Request Start Date"
        />
        <Field
          name="refundRequestEndDate"
          component={renderField}
          type="date"
          label="Request End Date"
        />
        <button className="history-btn" type="submit">
          Go
        </button>
      </Form>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    initialValues: {
      refundProcessedEndDate: state.history.refundProcessedEndDate,
      refundProcessedStartDate: state.history.refundProcessedStartDate,
    },
  };
};

const onSubmit = (
  {
    refundProcessedStartDate,
    refundProcessedEndDate,
    refundRequestStartDate,
    refundRequestEndDate,
  },
  dispatch
) =>
  dispatch(
    updateHistory(
      refundProcessedStartDate,
      refundProcessedEndDate,
      refundRequestStartDate,
      refundRequestEndDate
    )
  ).then(() => {
    dispatch(submit('historyPageFiltersForm'));
  });

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'historyParamsForm',
    onSubmit,
    validate,
  })
);

export default enhance(HistoryTableParams);
