import escapeStringRegexp from 'escape-string-regexp';
import React from 'react';

import PropTypes from '../../js/PropTypes';

// import './HighlightText.scss';

const HighlightText = ({ highlightTerm, text }) => {
  if (!highlightTerm) {
    return text;
  }

  const regExp = new RegExp(escapeStringRegexp(highlightTerm), 'gi');

  const highlightedText = [];
  let match;
  let previousIndex = 0;

  do {
    match = regExp.exec(text);
    if (match === null) {
      highlightedText.push(text.substring(previousIndex));
    } else {
      const { index } = match;

      highlightedText.push(text.substring(previousIndex, index));
      highlightedText.push(
        <span className="highlight-match">
          {text.substring(index, index + highlightTerm.length)}
        </span>
      );

      previousIndex = index + highlightTerm.length;
    }
  } while (match);

  return <span className="highlight-text">{highlightedText}</span>;
};

HighlightText.propTypes = {
  highlightTerm: PropTypes.string,
  text: PropTypes.string.isRequired,
};

HighlightText.defaultProps = {
  highlightTerm: null,
};

export default HighlightText;
