import React from 'react';
import { components } from 'react-select';
import classNames from 'classnames';

import HighlightText from '../HighlightText/HighlightText';

// import './Option.scss';

const Option = props => {
  const {
    label,
    selectProps: { isHighlightable, inputValue },
    data: { hasDivider },
  } = props;
  if (typeof label === 'string') {
    if (isHighlightable) {
      return (
        <components.Option
          className={classNames({ divider: hasDivider })}
          {...props}
        >
          <HighlightText highlightTerm={inputValue} text={label} />
        </components.Option>
      );
    }
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
          <label style={{ textAlign: 'right' }}>{props.label}</label>
        </div>
      </components.Option>
    );
  }

  if (!Array.isArray(label)) {
    throw new Error(
      'Option label type not supported. Must be string or array of strings.'
    );
  }

  return (
    <components.Option {...props}>
      <div className="option">
        {label.map(line => {
          const renderLine = isHighlightable ? (
            <HighlightText highlightTerm={inputValue} text={line} />
          ) : (
            line
          );
          return (
            <div className="option-line" key={line}>
              {renderLine}
            </div>
          );
        })}
      </div>
    </components.Option>
  );
};

export default Option;
