import PropTypes from 'prop-types';

import COMMON_CONSTANTS from './Constants';

const { CARD_VARIANTS } = COMMON_CONSTANTS;

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  node,
  number,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

const faqQuestions = arrayOf(
  shape({
    answer: node,
    id: string,
    question: string,
  })
);

const image = {
  alt: string.isRequired,
  className: string,
  errorSrc: string,
  id: string,
  src: string.isRequired,
};

const jsx = oneOfType([arrayOf(element), element, string]);

const tier = oneOf(['xs', 'sm', 'md', 'lg', 'xl']);

const responsiveImages = arrayOf(
  shape({
    tier,
    ...image,
  })
);

const tile = shape({
  id: string.isRequired,
  title: string.isRequired,
  thumbnailUrl: string.isRequired,
});

const button = shape({
  disabled: bool,
  onButtonClick: func,
  subText: string,
  text: string,
  url: string,
});

const baseCard = {
  id: string.isRequired,
  title: string.isRequired,
  thumbnailUrl: string,
  loading: bool,
  imageBadgeText: string,
  images: responsiveImages,
  defaultClick: shape({
    onClick: func,
  }),
  noBorder: bool,
  className: string,
};

const card = {
  ...baseCard,
  subTitle: string,
  description: string,
  primaryButtonText: string,
  primaryButtonSubtext: string,
  infoIcons: array,
  onPrimaryButtonClick: func,
  onSecondaryButtonClick: func,
};

const address = shape({
  addressLine1: string,
  addressLine2: string,
  city: string,
  state: string,
  zip: string,
});

const alert = shape({
  alertText: string.isRequired,
  button,
  id: string,
  icon: string,
});

const itineraryDay = {
  action: func,
  active: bool,
  city: string.isRequired,
  description: string,
  heading: string.isRequired,
  number: oneOfType([number, string]).isRequired,
};

const airItinerarySegment = shape({
  city: string,
  cityCode: string,
  date: string,
  time: string,
});

const imageTile = {
  image: string,
  url: string,
  disabled: bool,
  errorSrc: string,
};

const titleValuePair = PropTypes.shape({
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.number,
  ]),
}).isRequired;

const VikingPropTypes = {
  ...PropTypes,

  airItineraryLabels: shape({
    arrives: string,
    departs: string,
    flight: string,
    seats: string,
    passengers: string,
    manageFlight: string,
  }),

  airItinerary: shape({
    flight: shape({
      flightNumber: string,
      airlineName: string,
      duration: number,
      airlineBookingNumber: string,
    }),
    departs: airItinerarySegment,
    arrives: airItinerarySegment,
    passengers: arrayOf(
      shape({
        passengerNumber: number,
        eTicket: string,
      })
    ),
    seats: arrayOf(
      shape({
        seat: string,
        passengerNumber: number,
        airClassDescription: string,
      })
    ),
  }),

  alert,
  alerts: arrayOf(alert),

  button,

  baseCard,
  card: shape({
    ...card,
  }),

  cardSections: arrayOf(
    shape({
      title: string,
      cards: PropTypes.cards, // TODO: [MR-2892] This proptype doesn't exist and needs to be updated
      bannerNotification: alert,
      preContent: PropTypes.jsx,
    })
  ),

  cardVariant: oneOf(Object.values(CARD_VARIANTS)),

  dropdownPosition: oneOf(['left', 'right']),

  errorCodes: objectOf(string),

  errorMessages: objectOf(string),

  faqCategories: arrayOf(
    shape({
      active: bool,
      name: string,
      questions: faqQuestions,
    })
  ),

  gridDirection: oneOf(['horizontal', 'vertical']),

  icon: shape({
    label: string.isRequired,
    name: string.isRequired,
  }),

  iconPanelIcons: arrayOf(
    shape({
      count: number,
      onClick: func,
      variant: string,
    })
  ),

  image,

  input: arrayOf(
    shape({
      groupName: string,
      id: string.isRequired,
      label: jsx.isRequired,
    })
  ),

  itineraryDay,

  itineraryDays: arrayOf(shape(itineraryDay)),

  jsx,

  labelValue: shape({
    label: string,
    value: string,
  }),

  menuItems: arrayOf(
    shape({
      id: string.isRequired,
      isSmallText: string.isRequired,
      openInNewWindow: string,
      title: string.isRequired,
      url: string.isRequired,
    })
  ),

  reduxFormInput: shape({
    checked: bool,
    name: string,
    onBlur: func,
    onChange: func,
    onDragStart: func,
    onDrop: func,
    onFocus: func,
  }),

  reduxFormMeta: shape({
    active: bool,
    autoFilled: bool,
    asyncValidating: bool,
    dirty: bool,
    dispatch: func,
    error: string,
    form: string,
    invalid: bool,
    pristine: bool,
    submitting: bool,
    submitFailed: bool,
    touched: bool,
    valid: bool,
    visited: bool,
    warning: string,
  }),

  responsiveContent: arrayOf(
    shape({
      content: jsx.isRequired,
      tier,
    })
  ),

  responsiveImages,

  tabs: arrayOf(
    shape({
      active: bool,
      id: string,
      title: string,
      url: string,
    })
  ),

  tier,

  tile,

  tileGrid: arrayOf(tile),

  paymentMethodRowHeader: shape({
    title: string.isRequired,
    subtitle: string,
    button: {
      text: string.isRequired,
    },
  }),

  address,

  paymentMethodSavedItem: shape({
    accountName: string.isRequired,
    maskedAccountNumber: string,
    accountHolderName: string,
    billingAddress: address,
    buttons: arrayOf({ text: string }),
  }),

  itineraryItem: {
    dayValue: string,
    description: string,
  },

  history: shape({
    push: PropTypes.func.isRequired,
  }),

  heroImages: arrayOf(
    shape({
      tier: tier.isRequired,
      caption: string,
      src: string,
      alt: string,
    })
  ),

  imageTile,

  imageTiles: arrayOf(shape(imageTile)),

  homeBookingSummary: {
    bookingNumber: titleValuePair,
    dates: titleValuePair,
    daysToGo: titleValuePair,
    direction: titleValuePair,
    guests: titleValuePair,
  },
  customPageLink: PropTypes.shape({
    title: string,
    url: string,
  }),
};

export default VikingPropTypes;
