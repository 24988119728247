import React from 'react';

import PropTypes from '../../js/PropTypes';

// import './FieldValidationError.scss';

const FieldValidationError = ({ errorCode, errorCodes, errorMessages }) => (
  <span className="validation-error-text">
    {errorMessages[errorCodes[errorCode]] || errorCode}
  </span>
);

FieldValidationError.propTypes = {
  errorCode: PropTypes.string.isRequired,
  errorCodes: PropTypes.errorCodes,
  errorMessages: PropTypes.errorMessages,
};

FieldValidationError.defaultProps = {
  errorCodes: {},
  errorMessages: {},
};

export default FieldValidationError;
