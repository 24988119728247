import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

import './HistoryTable.scss';
import {
  getRecordStatus,
  getInvoice,
  getCurrency,
  getReqDate,
  getProcessedDate,
  getAgeDate,
  getProcessedBy,
  getProcessedStatus,
  getSource,
  getBookingStatus,
  getCruiseID,
  getSailDate,
  getName,
  getAcctType,
  getAccountNum,
  getTotalRefundAmt,
  getRefundReason,
} from './HistoryTableValues';
import { HISTORY_HEADERS, HISTORY_SORT_POSITION } from '../../util/constants';
import { sortHistory } from '../../actions/historyActions';

const HistoryTable = ({
  history,
  allHistory,
  loading,
  handleSort,
  sortDirection,
}) => {
  const getSortAttrs = (header, position) => ({
    onClick: () => handleSort(history, header, position, sortDirection),
    className: 'clickable',
  });

  const sortIcon = position =>
    sortDirection[position] === 1 ? <FaCaretDown /> : <FaCaretUp />;

  return (
    <div className="history">
      {history && history.length ? (
        <React.Fragment>
          <div className="header-messages">
            <p className="results-msg">
              Displaying {history.length} of {allHistory.length}
            </p>
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.RECORD_STATUS,
                    HISTORY_SORT_POSITION.RECORD_STATUS
                  )}
                >
                  Record Status {sortIcon(HISTORY_SORT_POSITION.RECORD_STATUS)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.INVOICE,
                    HISTORY_SORT_POSITION.INVOICE
                  )}
                >
                  Invoice {sortIcon(HISTORY_SORT_POSITION.INVOICE)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.CURRENCY,
                    HISTORY_SORT_POSITION.CURRENCY
                  )}
                >
                  Currency {sortIcon(HISTORY_SORT_POSITION.INVOICE)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.REQ_DATE,
                    HISTORY_SORT_POSITION.REQ_DATE
                  )}
                >
                  Req Date {sortIcon(HISTORY_SORT_POSITION.REQ_DATE)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.PROCESSED_DATE,
                    HISTORY_SORT_POSITION.PROCESSED_DATE
                  )}
                >
                  Processed Date{' '}
                  {sortIcon(HISTORY_SORT_POSITION.PROCESSED_DATE)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.AGE_DATE,
                    HISTORY_SORT_POSITION.AGE_DATE
                  )}
                >
                  Age Date {sortIcon(HISTORY_SORT_POSITION.AGE_DATE)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.PROCESSED_BY,
                    HISTORY_SORT_POSITION.PROCESSED_BY
                  )}
                >
                  Processed By {sortIcon(HISTORY_SORT_POSITION.PROCESSED_BY)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.ARA_PROCESSED,
                    HISTORY_SORT_POSITION.ARA_PROCESSED
                  )}
                >
                  ARA Processed {sortIcon(HISTORY_SORT_POSITION.ARA_PROCESSED)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.SOURCE,
                    HISTORY_SORT_POSITION.SOURCE
                  )}
                >
                  Source {sortIcon(HISTORY_SORT_POSITION.SOURCE)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.BOOKING_STATUS,
                    HISTORY_SORT_POSITION.BOOKING_STATUS
                  )}
                >
                  Booking Status{' '}
                  {sortIcon(HISTORY_SORT_POSITION.BOOKING_STATUS)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.CRUISE_ID,
                    HISTORY_SORT_POSITION.CRUISE_ID
                  )}
                >
                  Cruise ID {sortIcon(HISTORY_SORT_POSITION.CRUISE_ID)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.SAIL_DATE,
                    HISTORY_SORT_POSITION.SAIL_DATE
                  )}
                >
                  Sail Date {sortIcon(HISTORY_SORT_POSITION.SAIL_DATE)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.NAME,
                    HISTORY_SORT_POSITION.NAME
                  )}
                >
                  Name {sortIcon(HISTORY_SORT_POSITION.NAME)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.ACCT_TYPE,
                    HISTORY_SORT_POSITION.ACCT_TYPE
                  )}
                >
                  Type {sortIcon(HISTORY_SORT_POSITION.ACCT_TYPE)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.ACCT_NUM,
                    HISTORY_SORT_POSITION.ACCT_NUM
                  )}
                >
                  Acct No {sortIcon(HISTORY_SORT_POSITION.ACCT_NUM)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.TOTAL_REFUND_AMOUNT,
                    HISTORY_SORT_POSITION.TOTAL_REFUND_AMOUNT
                  )}
                >
                  Total Refund{' '}
                  {sortIcon(HISTORY_SORT_POSITION.TOTAL_REFUND_AMOUNT)}
                </td>
                <td
                  {...getSortAttrs(
                    HISTORY_HEADERS.REFUND_REASON,
                    HISTORY_SORT_POSITION.REFUND_REASON
                  )}
                >
                  Refund Reason {sortIcon(HISTORY_SORT_POSITION.REFUND_REASON)}
                </td>
              </tr>
            </thead>
            <tbody>
              {history.map(history => (
                <tr key={history.RefundId}>
                  <td>{getRecordStatus(history)}</td>
                  <td>{getInvoice(history)}</td>
                  <td>{getCurrency(history)}</td>
                  <td>{getReqDate(history)}</td>
                  <td>{getProcessedDate(history)}</td>
                  <td>{getAgeDate(history)}</td>
                  <td>{getProcessedBy(history)}</td>
                  <td>{getProcessedStatus(history)}</td>
                  <td>{getSource(history)}</td>
                  <td>{getBookingStatus(history)}</td>
                  <td>{getCruiseID(history)}</td>
                  <td>{getSailDate(history)}</td>
                  <td>{getName(history)}</td>
                  <td>{getAcctType(history)}</td>
                  <td>{getAccountNum(history)}</td>
                  <td>{getTotalRefundAmt(history)}</td>
                  <td>{getRefundReason(history)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      ) : (
        <div>
          {loading === true ? (
            <div className="loader-container">
              <Loader type="Oval" color="#456580" width="50" height="50" />
            </div>
          ) : (
            <h5>No results found</h5>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.history.loading,
  history: state.history.history,
  allHistory: state.history.allHistory,
  sortDirection: state.history.sortDirection,
});

const mapDispatchToProps = dispatch => ({
  handleSort: (history, header, position, sortDirection) => {
    dispatch(sortHistory(history, header, position, sortDirection));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTable);
