import React from 'react';
import logo from '../../../images/viking-logo.png';
import Header from '@viking-eng/header';

import Button from '@viking-eng/button';

import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { userLogout } from '../../util/adalConfig';

import './Header.scss';

const ARAHeader = ({ user }) => {
  const headerData = user
    ? {
        logo,
        items: [
          {
            id: 'welcome',
            isSmallText: 'false',
            openInNewWindow: 'false',
            title: `Welcome, ${user.name}`,
            url: '',
          },
          {
            id: 'current-refunds',
            isSmallText: 'true',
            openInNewWindow: 'false',
            title: 'Refunds',
            url: '/',
          },
          {
            id: 'error-page',
            isSmallText: 'true',
            openInNewWindow: 'false',
            title: 'Error',
            url: '/errors',
          },
          {
            id: 'history-page',
            isSmallText: 'true',
            openInNewWindow: 'false',
            title: 'Historical',
            url: '/history',
          },
        ],
      }
    : {
        logo: logo,
        items: [],
      };

  return (
    <div>
      {logo && (
        <Header header={headerData}>
          <div className="right-menu">
            <Button onButtonClick={() => userLogout()}>LOGOUT</Button>
          </div>
        </Header>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  handleLogout: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ARAHeader);
