import React from 'react';
import Button from '@viking-eng/button';
import Modal from '@viking-eng/modal';
import * as PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';

import {
  getGrossPrice,
  getGrossOutstanding,
  getNetOutstanding,
  getInvoiceNumber,
  getPaid,
  getRefund,
  getReqDate,
  getCruiseID,
  getBookingStatus,
  getShip,
  getVoyageCount,
  getSailDate,
  getSource,
  getShortpayAllow,
  getCurrency,
  getSaleDate,
  getCxlDate,
  getDaysFromDeparture,
  getCxlPenalty,
  getCxlReason,
  getCommission,
  getRefundReason,
  getReqBy,
  getReqRefund,
  getDiscAmt,
  getBalDueDate,
  getRefundComments,
  getReqStatus,
} from '../RefundTable/RefundTableValues';
import { formatCommentDateTime } from '../../util/common';
import { renderItem } from '../../util/view';
import { addCommentToBooking } from '../../actions/commentsActions';

import './BookingDetailsModal.scss';

const BookingDetailsModal = ({
  id,
  resetForm,
  handleSubmit,
  booking,
  comments,
  commentsLoading,
  user,
}) => {
  const onCancel = () => {
    comments = [];
    resetForm();
    window.$(`#${id}`).modal('hide');
  };

  return (
    <Modal id={id} onClose={onCancel}>
      <div className="booking-details-modal">
        {getInvoiceNumber(booking) !== null && (
          <div className="content">
            <div className="row invoice-number">
              Invoice Number: {getInvoiceNumber(booking)}
            </div>

            <div className="row booking-status">
              Status:
              <span className="color-viking-red">
                &nbsp;{getBookingStatus(booking)}
              </span>
            </div>

            <div className="row">
              <div className="col-sm">
                <div className="details-column">
                  {renderItem('Cruise ID', getCruiseID(booking))}
                  {renderItem('Ship', getShip(booking))}
                  {renderItem('Voyage Count', getVoyageCount(booking))}
                  {renderItem('Sail Date', getSailDate(booking))}
                  {renderItem('Source', getSource(booking))}
                  {renderItem('Shortpay Allow Flag', getShortpayAllow(booking))}
                </div>
              </div>
              <div className="col-sm">
                <div className="details-column">
                  {renderItem('Currency', getCurrency(booking))}
                  {renderItem('Original Sale Date', getSaleDate(booking))}
                  {renderItem('Balance Due Date', getBalDueDate(booking))}
                  {renderItem('Cancel Date', getCxlDate(booking))}
                  {renderItem(
                    'Cxl Days from Departure',
                    getDaysFromDeparture(booking)
                  )}
                  {renderItem('Cxl % Pentalty', getCxlPenalty(booking))}
                  {renderItem('Cxl Reason', getCxlReason(booking))}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <div className="details-column">
                  {renderItem('Gross Price', getGrossPrice(booking))}
                  {renderItem('Paid to Date', getPaid(booking))}
                  {renderItem(
                    'Gross Outstanding',
                    getGrossOutstanding(booking),
                    true
                  )}
                  {renderItem('Commission', getCommission(booking))}
                  {renderItem(
                    'Net Outstanding',
                    getNetOutstanding(booking),
                    true
                  )}
                </div>
              </div>
              <div className="col-sm">
                <div className="details-column">
                  {renderItem(
                    'Requested Refund Total',
                    getReqRefund(booking),
                    true
                  )}
                  {renderItem('Net Refund Amount', getRefund(booking), true)}
                  {renderItem('Discount Refund Amount', getDiscAmt(booking))}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <div className="details-column">
                  {renderItem('Refund Reason', getRefundReason(booking))}
                  {renderItem('Requested By', getReqBy(booking))}
                  {renderItem('Requested Date', getReqDate(booking))}
                </div>
              </div>
              <div className="col-sm">
                <div className="details-column">
                  {renderItem('Refund Request Status', getReqStatus(booking))}
                </div>
              </div>
            </div>

            <div className="row refund-comment">
              <span className="font-weight-bold">Refund Request Comment</span>
              :&nbsp;
              <span className="value">{getRefundComments(booking)}</span>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="row comment-history">
                <div className="title">Comment History:</div>
                {commentsLoading ? (
                  <div className="loader-container">
                    <Loader
                      type="Oval"
                      color="#456580"
                      width="50"
                      height="50"
                    />
                  </div>
                ) : (
                  comments.map(comment => (
                    <div className="previous-comments" key={comment.id}>
                      <div className="item">
                        <span className="font-weight-bold">
                          {comment.username}
                        </span>
                        <span className="value">
                          {' '}
                          {formatCommentDateTime(comment.created_at)}
                        </span>
                      </div>
                      <div className="item">
                        <span className="value">{comment.message}</span>
                      </div>
                    </div>
                  ))
                )}
                {comments.length === 0 && !commentsLoading && (
                  <div>No Comments</div>
                )}
              </div>

              <div className="row comment-input-box">
                <div className="input">
                  <Field
                    component="textarea"
                    rows={2}
                    placeholder="Enter comment..."
                    name="internalComments"
                  />
                </div>
              </div>

              <div className="row justify-content-end">
                <div className="btn-cancel">
                  <Button
                    className="hover-btn"
                    appearance="secondary-gray"
                    onButtonClick={onCancel}
                  >
                    CLOSE
                  </Button>
                </div>
                <Button
                  className="hover-btn"
                  appearance="secondary-blue"
                  attributes={{ type: 'submit' }}
                >
                  SAVE COMMENTS
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Modal>
  );
};

BookingDetailsModal.propTypes = {
  id: PropTypes.string,
  booking: PropTypes.any,
  onConfirm: PropTypes.func,
};

const onSubmit = (
  { internalComments },
  dispatch,
  { booking: { RefundId }, user: { evolutionId } }
) => {
  dispatch(
    addCommentToBooking(RefundId, internalComments, evolutionId)
  ).then(() => dispatch(reset('bookingDetails')));
};

const mapStateToProps = state => ({
  user: state.auth.user,
  comments: state.bookings.comments,
  commentsLoading: state.bookings.commentsLoading,
  initialValues: {
    internalComments: '',
  },
});

const mapDispatchToProps = dispatch => ({
  resetForm: () => dispatch(reset('bookingDetails')),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'bookingDetails',
    onSubmit,
    enableReinitialize: true,
  })
);

export default enhance(BookingDetailsModal);
