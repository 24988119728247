import React, { Component } from 'react';
import { connect } from 'react-redux';

import ErrorTable from '../../components/ErrorTable/ErrorTable';
import ErrorTableFilters from '../../components/ErrorTableFilters/ErrorTableFilters';
import { receiveErrors } from '../../actions/errorActions';
import { loginUser } from '../../actions/authActions';
import { getUserInfo } from '../../util/adalConfig';

class ErrorPage extends Component {
  constructor(props) {
    super(props);

    const { handleLogin } = this.props;
    handleLogin(getUserInfo());
  }

  async componentDidMount() {
    const { receiveErrors } = this.props;
    receiveErrors();
  }

  render() {
    return (
      <div className="col">
        <h5>Processing Errors</h5>
        <ErrorTableFilters />
        <ErrorTable />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  receiveErrors: () => dispatch(receiveErrors()),
  handleLogin: user => dispatch(loginUser(user)),
});

export default connect(
  null,
  mapDispatchToProps
)(ErrorPage);
