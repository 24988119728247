import moment from 'moment';
import {
  getBookings,
  getRefreshBookings,
  updateNeedsReview,
  updateInUse,
  submitRefunds,
} from '../api/api';
import {
  BOOKING_AGING_OPTIONS,
  BOOKING_SAIL_DATE_OPTIONS,
  BOOKING_STATUS,
  BOOKING_TYPE_OPTIONS,
  BOOKING_READINESS_OPTIONS,
  BOOKING_HEADERS,
  APPROVAL_STATUS,
} from '../util/constants';
import { getDaystoEmbarkation } from '../components/RefundTable/RefundTableValues';
import {
  isUndefinedOrNull,
  checkSystemReasons,
  reqDatesAreEqual,
  agingDatesAreEqual,
  namesAreEqual,
  accountNumsAreEqual,
  accountTypesAreEqual,
  getRefundAmt,
  isBookingGroup,
} from '../util/common';

const { DECLINED, PENDING } = APPROVAL_STATUS;
const {
  STATUS,
  INVOICE,
  EMBARK,
  NAME,
  REQ_DATE,
  AGE_DATE,
  ACCT_NUM,
  ACCT_TYPE,
  REFUND,
  PAID,
  CANCEL,
  GROSS,
  REVIEW,
  COMMENTS,
  CURRENCY,
  REASON,
  IN_USE,
} = BOOKING_HEADERS;
const {
  DIFFERENT_ADDRESS,
  UNSETTLED_ACH,
  UNSETTLED_CC,
  CXL_BOOKING_UNBALANCED,
  SHORTPAY_ALLOW,
} = BOOKING_STATUS;
export const GET_BOOKINGS_STARTED = 'GET_BOOKINGS_STARTED';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS_FAILURE = 'GET_BOOKINGS_FAILURE';
export const REFRESH_BOOKINGS_STARTED = 'REFRESH_BOOKINGS_STARTED';
export const REFRESH_BOOKINGS_SUCCESS = 'REFRESH_BOOKINGS_SUCCESS';
export const REFRESH_BOOKINGS_FAILURE = 'REFRESH_BOOKINGS_FAILURE';
export const UPDATE_ICON_STATUS = 'UPDATE_ICON_STATUS';
export const UPDATE_GROUP_ICON_STATUS = 'UPDATE_GROUP_ICON_STATUS';
export const SUBMIT_BOOKINGS_STARTED = 'SUBMIT_BOOKINGS_STARTED';
export const SUBMIT_BOOKINGS_SUCCESS = 'SUBMIT_BOOKINGS_SUCCESS';
export const SUBMIT_BOOKINGS_FAILURE = 'SUBMIT_BOOKINGS_FAILURE';
export const HIDE_MODAL = 'HIDE_MODAL';
export const FILTER = 'FILTER';
export const SEARCH_BOOKINGS = 'SEARCH_BOOKINGS';
export const SORT_BOOKINGS = 'SORT_BOOKINGS';
export const SUPERVISOR_REVIEW = 'SUPERVISOR_REVIEW';
export const LOCK_MULTIPLE_ROWS = 'LOCK_MULTIPLE_ROWS';
export const UNLOCK_MULTIPLE_ROWS = 'UNLOCK_MULTIPLE_ROWS';
export const APPROVE_MULTIPLE_ROWS = 'APPROVE_MULTIPLE_ROWS';
export const DECLINE_MULTIPLE_ROWS = 'DECLINE_MULTIPLE_ROWS';

export const receiveBookings = () => {
  return dispatch => {
    dispatch(receiveBookingsStarted());

    return getBookings()
      .then(response => {
        dispatch(receiveBookingsSuccess(response));
      })
      .catch(error => {
        dispatch(receiveBookingsFailure(error));
      });
  };
};

const receiveBookingsStarted = () => ({
  type: GET_BOOKINGS_STARTED,
  loading: true,
  payload: [],
});

const receiveBookingsSuccess = response => {
  const bookings = response.data.data;
  bookings.sort((a, b) => {
    return parseInt(a.Invoice) - parseInt(b.Invoice);
  });
  const filteredBookings = bookings.filter(
    booking => booking.Refunds[0].Status === 'R'
  );
  filteredBookings.forEach((booking, idx) => {
    const hasOnlyDifPayeeAddress =
      booking.systemReasons.length === 1 &&
      booking.systemReasons.includes(DIFFERENT_ADDRESS);
    const grossPriceZero = booking.GrossPrice === 0;
    const hasBlockedReason =
      booking.systemReasons.includes(UNSETTLED_ACH) ||
      booking.systemReasons.includes(UNSETTLED_CC) ||
      booking.systemReasons.includes(CXL_BOOKING_UNBALANCED) ||
      booking.systemReasons.includes(SHORTPAY_ALLOW);
    booking.approvalStatus =
      booking.systemReasons.length === 0 ||
      hasOnlyDifPayeeAddress ||
      (grossPriceZero && !hasBlockedReason)
        ? PENDING
        : DECLINED;
    booking.index = idx;
    return booking;
  });

  return {
    type: GET_BOOKINGS_SUCCESS,
    payload: filteredBookings,
  };
};

const receiveBookingsFailure = error => ({
  type: GET_BOOKINGS_FAILURE,
  payload: error,
});

export const refreshBookings = date => {
  return dispatch => {
    dispatch(refreshBookingsStarted());

    return getRefreshBookings(date)
      .then(response => {
        dispatch(refreshBookingsSuccess(response));
      })
      .catch(error => {
        dispatch(refreshBookingsFailure(error));
      });
  };
};

const refreshBookingsStarted = () => ({
  type: REFRESH_BOOKINGS_STARTED,
  payload: [],
});

const refreshBookingsSuccess = response => {
  const payload = response.data.data;

  return {
    type: REFRESH_BOOKINGS_SUCCESS,
    payload: payload,
  };
};

const refreshBookingsFailure = error => ({
  type: REFRESH_BOOKINGS_FAILURE,
  payload: error,
});

export const updateIconStatus = (header, bookings, RefundId, user) => {
  const evolutionId = isUndefinedOrNull(user.evolutionId)
    ? null
    : user.evolutionId;
  const isSupervisor = user.isSupervisor;

  return {
    type: UPDATE_ICON_STATUS,
    RefundId: RefundId,
    payload: bookings.map(booking => {
      if (booking.Refunds[0].RefundId === RefundId) {
        if (header === BOOKING_HEADERS.APPROVAL_STATUS) {
          let apprStatus;
          let lockedUser;
          if (isUndefinedOrNull(booking.inUseBy)) {
            updateInUse(booking, evolutionId);
          } else if (booking.inUseBy !== evolutionId) {
            return {
              ...booking,
              approvalStatus: booking.approvalStatus,
            };
          }
          if (!isUndefinedOrNull(booking.markedNeedsReview)) {
            return {
              ...booking,
              approvalStatus: APPROVAL_STATUS.DECLINED,
            };
          }
          if (booking.approvalStatus === APPROVAL_STATUS.PENDING) {
            apprStatus = APPROVAL_STATUS.APPROVED;
            lockedUser = evolutionId;
          } else if (booking.approvalStatus === APPROVAL_STATUS.APPROVED) {
            apprStatus = APPROVAL_STATUS.DECLINED;
            lockedUser = evolutionId;
          } else if (booking.approvalStatus === APPROVAL_STATUS.DECLINED) {
            if (booking.systemReasons) {
              apprStatus = booking.approvalStatus = APPROVAL_STATUS.APPROVED;
              lockedUser = evolutionId;
            } else {
              apprStatus = booking.approvalStatus = APPROVAL_STATUS.PENDING;
              lockedUser = evolutionId;
            }
          }
          return {
            ...booking,
            approvalStatus: apprStatus,
            inUseBy: lockedUser,
          };
        }
        if (header === BOOKING_HEADERS.REVIEW) {
          const systemReasons = checkSystemReasons(booking);
          if (
            isUndefinedOrNull(booking.markedNeedsReview) ||
            booking.markedNeedsReview === evolutionId ||
            isSupervisor
          ) {
            if (isUndefinedOrNull(booking.markedNeedsReview)) {
              updateNeedsReview(booking, evolutionId, isSupervisor);
              systemReasons.push(BOOKING_STATUS.NEEDS_SUP_REVIEW);
              return {
                ...booking,
                markedNeedsReview: evolutionId,
                approvalStatus: APPROVAL_STATUS.DECLINED,
                systemReasons: systemReasons,
              };
            } else {
              updateNeedsReview(booking, evolutionId, isSupervisor, true);
              systemReasons.filter(r => r !== BOOKING_STATUS.NEEDS_SUP_REVIEW);
              return {
                ...booking,
                markedNeedsReview: null,
                systemReasons: systemReasons,
              };
            }
          } else {
            return {
              ...booking,
            };
          }
        }
        if (header === BOOKING_HEADERS.IN_USE) {
          let approvalStatus;
          if (!isUndefinedOrNull(booking.inUseBy)) {
            if (booking.inUseBy !== evolutionId && !isSupervisor) {
              return {
                ...booking,
              };
            } else {
              updateInUse(booking, null);
              if (booking.systemReasons.length > 0) {
                approvalStatus = APPROVAL_STATUS.DECLINED;
              } else {
                approvalStatus = APPROVAL_STATUS.PENDING;
              }
              return {
                ...booking,
                approvalStatus: approvalStatus,
                inUseBy: null,
              };
            }
          } else {
            updateInUse(booking, evolutionId);
            return {
              ...booking,
              inUseBy: evolutionId,
            };
          }
        }
      }
      return booking;
    }),
  };
};

export const updateGroupIconStatus = (header, bookings, invoice, user) => {
  const evolutionId = isUndefinedOrNull(user.evolutionId)
    ? null
    : user.evolutionId;
  const isSupervisor = user.isSupervisor;
  return {
    type: UPDATE_GROUP_ICON_STATUS,
    invoice: invoice,
    payload: bookings.map(booking => {
      if (booking.Invoice === invoice) {
        if (header === BOOKING_HEADERS.APPROVAL_STATUS) {
          let apprStatus;
          let lockedUser;
          if (isUndefinedOrNull(booking.inUseBy)) {
            booking.Refunds.forEach(refund => updateInUse(refund, evolutionId));
          } else if (booking.inUseBy !== evolutionId) {
            return {
              ...booking,
              approvalStatus: booking.approvalStatus,
            };
          }
          if (!isUndefinedOrNull(booking.markedNeedsReview)) {
            return {
              ...booking,
              approvalStatus: APPROVAL_STATUS.DECLINED,
            };
          }
          if (booking.approvalStatus === APPROVAL_STATUS.PENDING) {
            apprStatus = APPROVAL_STATUS.APPROVED;
            lockedUser = evolutionId;
          } else if (booking.approvalStatus === APPROVAL_STATUS.APPROVED) {
            apprStatus = APPROVAL_STATUS.DECLINED;
            lockedUser = evolutionId;
          } else if (booking.approvalStatus === APPROVAL_STATUS.DECLINED) {
            if (booking.systemReasons) {
              apprStatus = booking.approvalStatus = APPROVAL_STATUS.APPROVED;
              lockedUser = evolutionId;
            } else {
              apprStatus = booking.approvalStatus = APPROVAL_STATUS.PENDING;
              lockedUser = evolutionId;
            }
          }
          return {
            ...booking,
            approvalStatus: apprStatus,
            inUseBy: lockedUser,
          };
        }
        if (header === BOOKING_HEADERS.REVIEW) {
          const systemReasons = checkSystemReasons(booking);
          let allRefunds = booking.Refunds;

          // Check that all bookings in group have same markedNeedsReview
          let checkNeedsReview = allRefunds
            .map(refund => refund.markedNeedsReview)
            .every((val, i, arr) => val === arr[0]);

          if (!checkNeedsReview) {
            updateNeedsReview(booking, evolutionId, isSupervisor, true);
            systemReasons.filter(r => r !== BOOKING_STATUS.NEEDS_SUP_REVIEW);
            return {
              ...booking,
              markedNeedsReview: null,
              systemReasons: systemReasons,
            };
          }
          if (
            isUndefinedOrNull(booking.markedNeedsReview) ||
            booking.markedNeedsReview === evolutionId ||
            isSupervisor
          ) {
            if (isUndefinedOrNull(booking.markedNeedsReview)) {
              updateNeedsReview(booking, evolutionId, isSupervisor);
              systemReasons.push(BOOKING_STATUS.NEEDS_SUP_REVIEW);
              return {
                ...booking,
                markedNeedsReview: evolutionId,
                approvalStatus: APPROVAL_STATUS.DECLINED,
                systemReasons: systemReasons,
              };
            } else {
              updateNeedsReview(booking, evolutionId, isSupervisor, true);
              systemReasons.filter(r => r !== BOOKING_STATUS.NEEDS_SUP_REVIEW);
              return {
                ...booking,
                markedNeedsReview: null,
                systemReasons: systemReasons,
              };
            }
          } else {
            return {
              ...booking,
            };
          }
        }
        if (header === BOOKING_HEADERS.IN_USE) {
          if (booking.inUseBy !== null) {
            if (booking.inUseBy !== evolutionId && !isSupervisor) {
              return {
                ...booking,
              };
            } else {
              booking.Refunds.forEach(refund => updateInUse(refund, null));
              let approvalStatus;
              if (getRefundAmt(booking) !== booking.Gross_Outstanding) {
                if (booking.Refunds.length > 1) {
                  const bookingGroupTotalRefund = booking.Refunds.reduce(
                    (sum, r) => {
                      let refundAmt = getRefundAmt(r, booking.BookingStatus);
                      if (refundAmt < 0) {
                        refundAmt *= -1;
                      }
                      return sum + refundAmt;
                    },
                    0
                  );
                  if (
                    bookingGroupTotalRefund !==
                    booking.Gross_Outstanding * -1
                  ) {
                    approvalStatus = APPROVAL_STATUS.DECLINED;
                  }
                }
              }
              if (booking.systemReasons.length > 0) {
                approvalStatus = APPROVAL_STATUS.DECLINED;
              } else {
                approvalStatus = APPROVAL_STATUS.PENDING;
              }
              return {
                ...booking,
                inUseBy: null,
                approvalStatus: approvalStatus,
              };
            }
          } else {
            booking.Refunds.forEach(refund => updateInUse(refund, evolutionId));
            return {
              ...booking,
              inUseBy: evolutionId,
            };
          }
        }
      }
      return booking;
    }),
  };
};

export const searchBookings = (
  bookings,
  {
    bookingNumber,
    type,
    readiness,
    currency,
    aging,
    needsSupervisor,
    sailDate,
    booking_status,
    refundReason,
    systemReason,
  }
) => {
  let foundBookings = bookings;

  if (typeof bookingNumber === 'string' && bookingNumber !== '') {
    let results = bookings.filter(booking => {
      const allRefunds = booking.Refunds;
      const allRefundLastNames = allRefunds.map(refund => refund.LastName);
      const allRefundFirstNames = allRefunds.map(refund => refund.FirstName);
      return (
        booking.Invoice.includes(bookingNumber) ||
        booking.Cruise.includes(bookingNumber) ||
        allRefundLastNames.some(name =>
          name.toLowerCase().includes(bookingNumber.toLowerCase())
        ) ||
        allRefundFirstNames.some(name =>
          name.toLowerCase().includes(bookingNumber.toLowerCase())
        )
      );
    });

    foundBookings = results;
    foundBookings.forEach((booking, idx) => (booking.index = idx));
  }

  if (!isUndefinedOrNull(type)) {
    foundBookings = foundBookings.filter(({ DirectFlag }) => {
      if (type === BOOKING_TYPE_OPTIONS.DIRECT) {
        return DirectFlag;
      } else if (type === BOOKING_TYPE_OPTIONS.TA) {
        return !DirectFlag;
      } else {
        return true;
      }
    });
    foundBookings.forEach((booking, idx) => (booking.index = idx));
  }

  if (typeof readiness !== 'undefined') {
    foundBookings = foundBookings.filter(({ approvalStatus }) => {
      if (readiness === BOOKING_READINESS_OPTIONS.READY_TO_GO) {
        return (
          approvalStatus === APPROVAL_STATUS.APPROVED ||
          approvalStatus === APPROVAL_STATUS.PENDING
        );
      } else if (readiness === BOOKING_READINESS_OPTIONS.NEEDS_REVIEW) {
        return approvalStatus === APPROVAL_STATUS.DECLINED;
      } else {
        return true;
      }
    });
    foundBookings.forEach((booking, idx) => (booking.index = idx));
  }

  if (!isUndefinedOrNull(currency)) {
    foundBookings = Array.isArray(currency)
      ? foundBookings.filter(({ Currency }) => currency.includes(Currency))
      : foundBookings.filter(({ Currency }) => Currency === currency);
    foundBookings.forEach((booking, idx) => (booking.index = idx));
  }

  if (!isUndefinedOrNull(booking_status) && booking_status.length > 0) {
    let filteredBookings,
      multiFilteredBookings = [];
    booking_status.forEach(option => {
      filteredBookings = foundBookings.filter(
        ({ BookingStatusText }) => BookingStatusText === option.value
      );
      multiFilteredBookings = multiFilteredBookings.concat(filteredBookings);
    });
    foundBookings = multiFilteredBookings;
    foundBookings.forEach((booking, idx) => (booking.index = idx));
  }

  if (!isUndefinedOrNull(aging) && aging.length > 0) {
    let dateFilter;
    let filteredBookings,
      multiFilteredBookings = [];
    aging.forEach(option => {
      if (option.value === BOOKING_AGING_OPTIONS.TODAY) {
        dateFilter = daysCount => daysCount <= 1;
      }
      if (option.value === BOOKING_AGING_OPTIONS.ONE_WEEK) {
        dateFilter = daysCount => daysCount > 1 && daysCount <= 7.99;
      }
      if (option.value === BOOKING_AGING_OPTIONS.TWO_WEEKS) {
        dateFilter = daysCount => daysCount > 7 && daysCount <= 14.99;
      }
      if (option.value === BOOKING_AGING_OPTIONS.THREE_WEEKS) {
        dateFilter = daysCount => daysCount > 14 && daysCount <= 21.99;
      }
      if (option.value === BOOKING_AGING_OPTIONS.MORE_THAN_3_WEEKS) {
        dateFilter = daysCount => daysCount > 22;
      }
      filteredBookings = foundBookings.filter(booking => {
        if (isBookingGroup(booking)) {
          const allRefundRequestDates = booking.Refunds.map(refund =>
            moment(refund.RequestDate)
          );
          const oldestRequestDate = moment.min(allRefundRequestDates);
          return dateFilter(moment().diff(oldestRequestDate, 'days', true));
        } else {
          const reqDate = booking.Refunds[0].RequestDate;
          return dateFilter(moment().diff(reqDate, 'days', true));
        }
      });
      multiFilteredBookings = multiFilteredBookings.concat(filteredBookings);
      multiFilteredBookings = Array.from(
        new Set(multiFilteredBookings.map(b => b.Invoice))
      ).map(Invoice => {
        return multiFilteredBookings.find(b => b.Invoice === Invoice);
      });
    });
    // Redo indexing for multi-select
    foundBookings = multiFilteredBookings;
    foundBookings.forEach((booking, idx) => (booking.index = idx));
  }

  if (!isUndefinedOrNull(needsSupervisor)) {
    if (needsSupervisor === true) {
      foundBookings = foundBookings.filter(
        ({ markedNeedsReview }) => !isUndefinedOrNull(markedNeedsReview)
      );
      foundBookings.forEach((booking, idx) => (booking.index = idx));
    } else {
      foundBookings = foundBookings.filter(({ markedNeedsReview }) =>
        isUndefinedOrNull(markedNeedsReview)
      );
      foundBookings.forEach((booking, idx) => (booking.index = idx));
    }
    foundBookings.forEach((booking, idx) => (booking.index = idx));
  }

  if (!isUndefinedOrNull(sailDate) && sailDate.length > 0) {
    let dateFilter;
    let filteredBookings,
      multiFilteredBookings = [];
    sailDate.forEach(option => {
      if (option.value === BOOKING_SAIL_DATE_OPTIONS.PAST) {
        dateFilter = daysSinceSailDate => daysSinceSailDate <= 0;
      }
      if (option.value === BOOKING_SAIL_DATE_OPTIONS.FIFTEEN) {
        dateFilter = daysSinceSailDate =>
          daysSinceSailDate > 0 && daysSinceSailDate <= 15;
      }
      if (option.value === BOOKING_SAIL_DATE_OPTIONS.FIFTEEN_FORTYFIVE) {
        dateFilter = daysSinceSailDate =>
          daysSinceSailDate >= 16 && daysSinceSailDate <= 45;
      }
      if (option.value === BOOKING_SAIL_DATE_OPTIONS.MORE_THAN_FORTYFIVE) {
        dateFilter = daysSinceSailDate => daysSinceSailDate >= 46;
      }
      filteredBookings = foundBookings.filter(booking =>
        dateFilter(getDaystoEmbarkation(booking), 'days', true)
      );
      multiFilteredBookings = multiFilteredBookings.concat(filteredBookings);
      multiFilteredBookings = Array.from(
        new Set(multiFilteredBookings.map(b => b.Invoice))
      ).map(Invoice => {
        return multiFilteredBookings.find(b => b.Invoice === Invoice);
      });
    });
    // Redo indexing for multi-select
    foundBookings = multiFilteredBookings;
    foundBookings.forEach((booking, idx) => (booking.index = idx));
  }

  if (!isUndefinedOrNull(refundReason) && refundReason.length > 0) {
    let filteredBookings,
      multiFilteredBookings = [];
    refundReason.forEach(option => {
      filteredBookings = foundBookings.filter(booking => {
        if (isBookingGroup(booking)) {
          const allRefundReasons = booking.Refunds.map(
            refund => refund.RefundReason
          );
          return allRefundReasons.includes(option.value);
        } else {
          return booking.Refunds[0].RefundReason === option.value;
        }
      });
      multiFilteredBookings = multiFilteredBookings.concat(filteredBookings);
    });
    // Redo indexing for multi-select
    foundBookings = multiFilteredBookings;
    foundBookings.forEach((booking, idx) => (booking.index = idx));
  }

  if (!isUndefinedOrNull(systemReason) && systemReason.length > 0) {
    let filteredBookings,
      multiFilteredBookings = [];
    systemReason.forEach(option => {
      filteredBookings = foundBookings.filter(({ systemReasons }) =>
        systemReasons.includes(option.value)
      );
      multiFilteredBookings = Array.from(
        new Set(multiFilteredBookings.map(b => b.RefundId))
      ).map(RefundId => {
        return multiFilteredBookings.find(b => b.RefundId === RefundId);
      });
      multiFilteredBookings = multiFilteredBookings.concat(filteredBookings);
    });
    // Redo indexing for multi-select
    foundBookings = multiFilteredBookings;
    foundBookings.forEach((booking, idx) => (booking.index = idx));
  }
  return {
    type: SEARCH_BOOKINGS,
    payload: foundBookings,
  };
};

export const sortBookings = (
  bookings,
  header,
  position,
  direction,
  user = ''
) => {
  const mustBeEqualForMulti = [REQ_DATE, AGE_DATE, NAME, ACCT_NUM, ACCT_TYPE];
  let sortedBookings = [...bookings];
  let multiRefundBookings = bookings.filter(
    booking => booking.Refunds.length > 1
  );

  let equalInvoiceNums;

  // Isolate Invoice numbers for bookings with multiple refund requests
  let multiRefundBookingsInvoiceNums = multiRefundBookings.map(b => b.Invoice);

  // Remove bookings with multiple refund requets from main bookings array
  const getRemainingBookings = bookings =>
    bookings.filter(b => !multiRefundBookingsInvoiceNums.includes(b.Invoice));

  // Check if column is equal across all refund requests under same invoice number
  const getEqualInvoiceNums = functionToCheck =>
    multiRefundBookings
      .filter(b => functionToCheck(b.Refunds))
      .map(b => b.Invoice);

  const removeEqualValMultiRefundBookings = bookings =>
    bookings.filter(booking =>
      multiRefundBookingsInvoiceNums.includes(booking.Invoice)
    );

  direction = direction.map((colDirection, index) => {
    if (index === position) {
      return colDirection === -1 ? 1 : -1;
    }
    return 1;
  });

  let filter;
  switch (header) {
    case STATUS:
      filter = (a, b) => (a.approvalStatus >= b.approvalStatus ? 1 : -1);
      break;
    case INVOICE:
      filter = (a, b) => parseInt(a.Invoice) - parseInt(b.Invoice);
      break;
    case CURRENCY:
      filter = (a, b) => a.Currency.localeCompare(b.Currency);
      break;
    case EMBARK:
      filter = (a, b) =>
        moment(a.SailDate).unix() >= moment(b.SailDate).unix() ? 1 : -1;
      break;
    case REQ_DATE:
      equalInvoiceNums = getEqualInvoiceNums(reqDatesAreEqual);

      // Remove equal invoice nums from group invoice nums
      multiRefundBookingsInvoiceNums = multiRefundBookingsInvoiceNums.filter(
        i => !equalInvoiceNums.includes(i)
      );

      // Get individual lines of bookings with multiple refund requests
      multiRefundBookings = removeEqualValMultiRefundBookings(sortedBookings);

      // Remove bookings with multiple refund requets from main bookings array
      sortedBookings = getRemainingBookings(sortedBookings);

      filter = (a, b) =>
        moment(a.Refunds[0].RequestDate).unix() >=
        moment(b.Refunds[0].RequestDate).unix()
          ? 1
          : -1;
      break;
    case AGE_DATE:
      equalInvoiceNums = getEqualInvoiceNums(agingDatesAreEqual);

      multiRefundBookingsInvoiceNums = multiRefundBookingsInvoiceNums.filter(
        i => !equalInvoiceNums.includes(i)
      );

      multiRefundBookings = removeEqualValMultiRefundBookings(sortedBookings);

      sortedBookings = getRemainingBookings(sortedBookings);

      filter = (a, b) =>
        moment(a.Refunds[0].RequestDate).unix() <=
        moment(b.Refunds[0].RequestDate).unix()
          ? 1
          : -1;
      break;
    case NAME:
      equalInvoiceNums = getEqualInvoiceNums(namesAreEqual);

      multiRefundBookingsInvoiceNums = multiRefundBookingsInvoiceNums.filter(
        i => !equalInvoiceNums.includes(i)
      );

      multiRefundBookings = removeEqualValMultiRefundBookings(sortedBookings);

      sortedBookings = getRemainingBookings(sortedBookings);

      filter = (a, b) =>
        a.Refunds[0].LastName.localeCompare(b.Refunds[0].LastName);
      break;
    case ACCT_TYPE:
      equalInvoiceNums = getEqualInvoiceNums(accountTypesAreEqual);

      multiRefundBookingsInvoiceNums = multiRefundBookingsInvoiceNums.filter(
        i => !equalInvoiceNums.includes(i)
      );

      multiRefundBookings = removeEqualValMultiRefundBookings(sortedBookings);

      sortedBookings = getRemainingBookings(sortedBookings);
      filter = (a, b) =>
        a.Refunds[0].AccountType >= b.Refunds[0].AccountType ? 1 : -1;
      break;
    case ACCT_NUM:
      equalInvoiceNums = getEqualInvoiceNums(accountNumsAreEqual);

      multiRefundBookingsInvoiceNums = multiRefundBookingsInvoiceNums.filter(
        i => !equalInvoiceNums.includes(i)
      );

      multiRefundBookings = removeEqualValMultiRefundBookings(sortedBookings);

      sortedBookings = getRemainingBookings(sortedBookings);

      filter = (a, b) =>
        a.Refunds[0].AccountNumber.slice(-6) >=
        b.Refunds[0].AccountNumber.slice(-6)
          ? 1
          : -1;
      break;
    case REFUND:
      multiRefundBookings.forEach(
        b =>
          (b.TotalGroupRefundAmount = b.Refunds.reduce((sum, r) => {
            let refundAmt = getRefundAmt(r, b.BookingStatus);
            return sum + refundAmt;
          }, 0))
      );

      const getRefundProp = booking =>
        booking.Refunds.length > 1
          ? parseFloat(booking.TotalGroupRefundAmount)
          : booking.BookingStatus === 'K'
          ? parseFloat(booking.Refunds[0].Amount)
          : parseFloat(booking.Refunds[0].TotalRefundAmount);

      filter = (a, b) => getRefundProp(b) - getRefundProp(a);
      break;
    case PAID:
      filter = (a, b) => parseInt(a.RecAmt) - parseInt(b.RecAmt);
      break;
    case CANCEL:
      filter = (a, b) => parseInt(a.GrossPrice) - parseInt(b.GrossPrice);
      break;
    case GROSS:
      filter = (a, b) =>
        parseInt(b.Gross_Outstanding) - parseInt(a.Gross_Outstanding);
      break;
    case REVIEW:
      filter = (a, b) =>
        isUndefinedOrNull(a.markedNeedsReview) &&
        !isUndefinedOrNull(b.markedNeedsReview)
          ? 1
          : -1;
      break;
    case COMMENTS:
      filter = (a, b) => (!a.has_comments && b.has_comments ? 1 : -1);
      break;
    case REASON:
      filter = (a, b) => (!a.systemReasons && b.systemReasons ? 1 : -1);
      break;
    case IN_USE:
      filter = (a, b) => {
        // Sort by current user
        if (a.inUseBy === user && a.inUseBy !== b.inUseBy) return -1;
        if (b.inUseBy === user && b.inUseBy !== a.inUseBy) return 1;

        // Sort by null
        if (a.inUseBy === null && b.inUseBy !== null) return 1;
        if (b.inUseBy === null && a.inUseby !== null) return -1;
      };
      break;
    default:
      break;
  }

  if (direction[position] === -1) {
    // Filter both bookings array based on sort
    sortedBookings = sortedBookings.sort(filter);

    // Add back groupedBookings to end of array
    if (multiRefundBookings && mustBeEqualForMulti.includes(header)) {
      sortedBookings = sortedBookings.concat(multiRefundBookings);
    }

    // Redo indexing for multi-select
    sortedBookings.forEach((booking, idx) => (booking.index = idx));
  } else if (direction[position] === 1) {
    // Reverse the sort of main bookings array
    sortedBookings = sortedBookings.reverse();

    // Add back groupedBookings to end of array
    if (multiRefundBookings && mustBeEqualForMulti.includes(header)) {
      sortedBookings = sortedBookings.concat(multiRefundBookings);
    }

    // Redo indexing for multi-select
    sortedBookings.forEach((booking, idx) => (booking.index = idx));
  }

  return {
    type: SORT_BOOKINGS,
    direction,
    payload: sortedBookings,
  };
};

export const submitBookings = (bookings, user) => {
  return dispatch => {
    dispatch(submitBookingsStarted());

    const errorMessage =
      'There was an error processing the bookings. Please try again.';

    return submitRefunds(bookings, user.evolutionId)
      .then(response => {
        dispatch(submitBookingsSuccess(response));
      })
      .catch(error => {
        if (error.response.data !== undefined) {
          dispatch(submitBookingsFailure(error.response.data.data));
        } else {
          // External error
          console.error(error);
          dispatch(submitBookingsFailure(errorMessage));
        }
      });
  };
};

const submitBookingsStarted = () => ({
  type: SUBMIT_BOOKINGS_STARTED,
  payload: [],
});

const submitBookingsSuccess = response => {
  const payload = response.data.data;

  return {
    type: SUBMIT_BOOKINGS_SUCCESS,
    payload: payload,
  };
};

const submitBookingsFailure = error => {
  return {
    type: SUBMIT_BOOKINGS_FAILURE,
    payload: error,
  };
};

export const hideModal = modal => ({
  type: HIDE_MODAL,
  payload: modal,
});

export const lockMultipleRows = (idx1, idx2, bookings, user) => {
  let evolutionId = user.evolutionId;
  idx1 = parseInt(idx1);
  idx2 = parseInt(idx2);
  if (idx2 > idx1) {
    for (var i = idx1; i <= idx2; i++) {
      if (isUndefinedOrNull(bookings[i].inUseBy)) {
        bookings[i].inUseBy = evolutionId;
        updateInUse(bookings[i], evolutionId);
      }
    }
  } else {
    for (var j = idx1 - 1; j >= idx2; j--) {
      if (isUndefinedOrNull(bookings[j].inUseBy)) {
        bookings[j].inUseBy = evolutionId;
        updateInUse(bookings[j], evolutionId);
      }
    }
  }
  return {
    type: LOCK_MULTIPLE_ROWS,
    payload: bookings,
  };
};

export const unlockMultipleRows = (idx1, idx2, bookings, user) => {
  let evolutionId = user.evolutionId;
  idx1 = parseInt(idx1);
  idx2 = parseInt(idx2);
  if (idx2 > idx1) {
    for (var i = idx1; i <= idx2; i++) {
      if (bookings[i].inUseBy === evolutionId) {
        bookings[i].inUseBy = null;
        bookings[i].systemReasons.length > 0
          ? (bookings[i].approvalStatus = APPROVAL_STATUS.DECLINED)
          : (bookings[i].approvalStatus = APPROVAL_STATUS.PENDING);
        updateInUse(bookings[i], null);
      }
    }
  } else {
    for (var j = idx1 - 1; j > idx2; j--) {
      if (bookings[j].inUseBy === evolutionId) {
        bookings[j].inUseBy = null;
        bookings[j].systemReasons.length > 0
          ? (bookings[j].approvalStatus = APPROVAL_STATUS.DECLINED)
          : (bookings[j].approvalStatus = APPROVAL_STATUS.PENDING);
        updateInUse(bookings[j], null);
      }
    }
  }
  return {
    type: UNLOCK_MULTIPLE_ROWS,
    payload: bookings,
  };
};

export const approveMultipleRows = (idx1, idx2, bookings, user) => {
  let evolutionId = user.evolutionId;
  idx1 = parseInt(idx1);
  idx2 = parseInt(idx2);
  if (idx2 > idx1) {
    for (var i = idx1 + 1; i <= idx2; i++) {
      if (bookings[i].markedNeedsReview) {
        continue;
      } else if (
        isUndefinedOrNull(bookings[i].inUseBy) ||
        bookings[i].inUseBy === evolutionId
      ) {
        bookings[i].approvalStatus = APPROVAL_STATUS.APPROVED;
        bookings[i].inUseBy = evolutionId;
        updateInUse(bookings[i], evolutionId);
      }
    }
  } else {
    for (var j = idx1 - 1; j >= idx2; j--) {
      if (bookings[j].markedNeedsReview) {
        continue;
      } else if (
        isUndefinedOrNull(bookings[j].inUseBy) ||
        bookings[j].inUseBy === evolutionId
      ) {
        bookings[j].approvalStatus = APPROVAL_STATUS.APPROVED;
        bookings[j].inUseBy = evolutionId;
        updateInUse(bookings[j], evolutionId);
      }
    }
  }
  return {
    type: APPROVE_MULTIPLE_ROWS,
    payload: bookings,
  };
};

export const declineMultipleRows = (idx1, idx2, bookings, user) => {
  let evolutionId = user.evolutionId;
  idx1 = parseInt(idx1);
  idx2 = parseInt(idx2);
  if (idx2 > idx1) {
    for (var i = idx1; i <= idx2; i++) {
      if (bookings[i].markedNeedsReview) {
        continue;
      } else if (
        isUndefinedOrNull(bookings[i].inUseBy) ||
        bookings[i].inUseBy === evolutionId
      ) {
        bookings[i].approvalStatus = APPROVAL_STATUS.DECLINED;
        bookings[i].inUseBy = evolutionId;
        updateInUse(bookings[i], evolutionId);
      }
    }
  } else {
    for (var j = idx1 - 1; j >= idx2; j--) {
      if (bookings[j].markedNeedsReview) {
        continue;
      } else if (
        isUndefinedOrNull(bookings[j].inUseBy) ||
        bookings[j].inUseBy === evolutionId
      ) {
        bookings[j].approvalStatus = APPROVAL_STATUS.DECLINED;
        bookings[j].inUseBy = evolutionId;
        updateInUse(bookings[j], evolutionId);
      }
    }
  }
  return {
    type: DECLINE_MULTIPLE_ROWS,
    payload: bookings,
  };
};
