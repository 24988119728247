import React, { Component } from 'react';
import RefundTable from '../../components/RefundTable/RefundTable';
import { connect } from 'react-redux';
import RefundTableFilters from '../../components/RefundTableFilters/RefundTableFilters';
import { receiveBookings, refreshBookings } from '../../actions/bookingActions';
import { loginUser } from '../../actions/authActions';
import moment from 'moment';
import { getUserInfo } from '../../util/adalConfig';

class RefundTablePage extends Component {
  state = { isListeningChanges: false };

  constructor(props) {
    super(props);

    const { handleLogin } = this.props;
    handleLogin(getUserInfo());
  }

  async componentDidMount() {
    const { receiveBookings } = this.props;
    receiveBookings();
  }

  componentDidUpdate() {
    if (this.props.allBookings.length > 0 && !this.state.isListeningChanges) {
      this.setState({ isListeningChanges: true });
      this.listenBookingsChanges();
    }
  }

  listenBookingsChanges = () => {
    const { refreshBookings } = this.props;

    // Don't listen for changes if environment is set to local development
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
      setInterval(() => {
        let { updatedDate } = this.props;
        refreshBookings(moment.utc(updatedDate).format('YYYY-MM-DD HH:mm:ss'));
      }, 1500);
    }
  };

  render() {
    const { bookings, allBookings, checkForApproval } = this.props;
    return (
      <div className="col">
        <h5>Refunds</h5>
        <RefundTableFilters />
        <RefundTable
          bookings={bookings}
          allBookings={allBookings}
          checkForApproval={() => checkForApproval(bookings)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bookings: state.bookings.bookings,
  allBookings: state.bookings.allBookings,
  updatedDate: state.bookings.updatedDate,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  receiveBookings: () => dispatch(receiveBookings()),
  refreshBookings: date => dispatch(refreshBookings(date)),
  handleLogin: user => dispatch(loginUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefundTablePage);
