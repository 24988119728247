import moment from 'moment';

import { getHistory, getUpdatedHistory } from '../api/api';
import {
  HISTORY_HEADERS,
  HISTORY_PROCESSED_DATE_OPTIONS,
} from '../util/constants';
import { isUndefinedOrNull } from '../util/common';

export const GET_HISTORY_STARTED = 'GET_HISTORY_STARTED';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAILURE = 'GET_HISTORY_FAILURE';
export const SEARCH_HISTORY = 'SEARCH_HISTORY';
export const SORT_HISTORY = 'SORT_HISTORY';
export const UPDATE_HISTORY_STARTED = 'UPDATE_HISTORY_STARTED';
export const UPDATE_HISTORY_SUCCESS = 'UPDATE_HISTORY_SUCCESS';
export const UPDATE_HISTORY_FAILURE = 'UPDATE_HISTORY_FAILURE';

export const receiveHistory = () => {
  return dispatch => {
    dispatch(receiveHistoryStarted());

    return getHistory()
      .then(response => {
        dispatch(receiveHistorySuccess(response));
      })
      .catch(error => {
        dispatch(receiveHistoryFailure(error));
      });
  };
};

const receiveHistoryStarted = () => ({
  type: GET_HISTORY_STARTED,
  loading: true,
  payload: [],
});

const receiveHistorySuccess = response => {
  const history = [...response.data.data].sort(
    (a, b) => parseInt(a.Invoice) - parseInt(b.Invoice)
  );

  return {
    type: GET_HISTORY_SUCCESS,
    payload: history,
  };
};

const receiveHistoryFailure = error => {
  return {
    type: GET_HISTORY_FAILURE,
    payload: error,
  };
};

export const searchHistories = (
  histories,
  {
    historyNumber,
    processedDate,
    processedBy,
    araProcessed,
    bookingStatus,
    refundReason,
    type,
  }
) => {
  let foundHistories = histories;

  if (typeof historyNumber === 'string' && historyNumber !== '') {
    let results = histories.filter(history => {
      return (
        history.Invoice.includes(historyNumber) ||
        history.Cruise.includes(historyNumber) ||
        history.LastName.toLowerCase().includes(historyNumber.toLowerCase()) ||
        history.FirstName.toLowerCase().includes(historyNumber.toLowerCase())
      );
    });
    foundHistories = results;
  }

  if (!isUndefinedOrNull(processedDate) && processedDate.length > 0) {
    let dateFilter;
    let filteredHistories,
      multiFilteredHistories = [];

    processedDate.forEach(option => {
      let value = option.value;
      if (value === HISTORY_PROCESSED_DATE_OPTIONS.TODAY) {
        dateFilter = daysCount => daysCount <= 1;
      }
      if (value === HISTORY_PROCESSED_DATE_OPTIONS.LAST_WEEK) {
        dateFilter = daysCount => daysCount > 1 && daysCount <= 7;
      }
      if (value === HISTORY_PROCESSED_DATE_OPTIONS.TWO_WEEKS) {
        dateFilter = daysCount => daysCount > 7 && daysCount <= 14;
      }
      if (value === HISTORY_PROCESSED_DATE_OPTIONS.LAST_MONTH) {
        dateFilter = daysCount => daysCount > 14 && daysCount <= 30;
      }
      filteredHistories = foundHistories.filter(({ processedDate }) =>
        dateFilter(moment().diff(processedDate, 'days', true))
      );
      multiFilteredHistories = multiFilteredHistories.concat(filteredHistories);
    });
    foundHistories = multiFilteredHistories;
  }

  if (!isUndefinedOrNull(processedBy) && processedBy.length > 0) {
    let filteredHistories,
      multiFilteredHistories = [];

    processedBy.forEach(option => {
      filteredHistories = foundHistories.filter(
        ({ ModifiedUserId }) => ModifiedUserId === option.value
      );
      multiFilteredHistories = multiFilteredHistories.concat(filteredHistories);
    });
    foundHistories = multiFilteredHistories;
  }

  if (!isUndefinedOrNull(araProcessed)) {
    foundHistories = foundHistories.filter(
      ({ ProcessedAPI }) => ProcessedAPI === araProcessed
    );
  }

  if (!isUndefinedOrNull(bookingStatus)) {
    foundHistories = foundHistories.filter(
      ({ StatusText }) => StatusText === bookingStatus
    );
  }

  if (!isUndefinedOrNull(refundReason) && refundReason.length > 0) {
    let filteredHistories,
      multiFilteredHistories = [];
    refundReason.forEach(option => {
      filteredHistories = foundHistories.filter(
        ({ RefundReason }) => RefundReason === option.value
      );
      multiFilteredHistories = multiFilteredHistories.concat(filteredHistories);
    });
    foundHistories = multiFilteredHistories;
  }

  if (!isUndefinedOrNull(type) && type.length > 0) {
    let filteredHistories,
      multiFilteredHistories = [];
    type.forEach(option => {
      filteredHistories = foundHistories.filter(
        ({ RefundType }) => RefundType === option.value
      );
      multiFilteredHistories = multiFilteredHistories.concat(filteredHistories);
    });
    foundHistories = multiFilteredHistories;
  }

  return {
    type: SEARCH_HISTORY,
    payload: foundHistories,
  };
};

export const sortHistory = (history, header, position, direction) => {
  let sortedHistory = [...history];
  direction = direction.map((colDirection, index) => {
    if (index === position) {
      return colDirection === -1 ? 1 : -1;
    }
    return 1;
  });

  if (direction[position] === -1) {
    let filter;
    switch (header) {
      case HISTORY_HEADERS.RECORD_STATUS:
        filter = (a, b) => a.RequestStatus.localeCompare(b.RequestStatus);
        break;
      case HISTORY_HEADERS.INVOICE:
        filter = (a, b) => parseInt(a.Invoice) - parseInt(b.Invoice);
        break;
      case HISTORY_HEADERS.CURRENCY:
        filter = (a, b) => a.Currency.localeCompare(b.Currency);
        break;
      case HISTORY_HEADERS.REQ_DATE:
        filter = (a, b) =>
          moment(a.RequestDate).unix() >= moment(b.RequestDate).unix() ? 1 : -1;
        break;
      case HISTORY_HEADERS.PROCESSED_DATE:
        filter = (a, b) =>
          a.ProcessedDate === null
            ? -1
            : b.ProcessedDate === null
            ? 1
            : moment(a.ProcessedDate).unix() - moment(b.ProcessedDate).unix();
        break;
      case HISTORY_HEADERS.AGE_DATE:
        filter = (a, b) =>
          a.ProcessedDate === null
            ? -1
            : b.ProcessedDate === null
            ? 1
            : moment(a.RequestDate).diff(a.ProcessedDate) <=
              moment(b.RequestDate).diff(b.ProcessedDate)
            ? 1
            : -1;
        break;
      case HISTORY_HEADERS.PROCESSED_BY:
        filter = (a, b) =>
          a.ModifiedUserId.toUpperCase().localeCompare(
            b.ModifiedUserId.toUpperCase()
          );
        break;
      case HISTORY_HEADERS.ARA_PROCESSED:
        filter = (a, b) =>
          a.ProcessedAPI === b.ProcessedAPI ? 0 : a.ProcessedAPI ? 1 : -1;
        break;
      case HISTORY_HEADERS.SOURCE:
        filter = (a, b) => a.Source.localeCompare(b.Source);
        break;
      case HISTORY_HEADERS.BOOKING_STATUS:
        filter = (a, b) => a.StatusText.localeCompare(b.StatusText);
        break;
      case HISTORY_HEADERS.CRUISE_ID:
        filter = (a, b) => a.Cruise.localeCompare(b.Cruise);
        break;
      case HISTORY_HEADERS.SAIL_DATE:
        filter = (a, b) =>
          moment(a.SailDate).unix() >= moment(b.SailDate).unix() ? 1 : -1;
        break;
      case HISTORY_HEADERS.NAME:
        filter = (a, b) => a.LastName.localeCompare(b.LastName);
        break;
      case HISTORY_HEADERS.ACCT_TYPE:
        filter = (a, b) => a.RefundType.localeCompare(b.RefundType);
        break;
      case HISTORY_HEADERS.ACCT_NUM:
        filter = (a, b) =>
          a.AccountNumber.slice(-6) >= b.AccountNumber.slice(-6) ? 1 : -1;
        break;
      case HISTORY_HEADERS.TOTAL_REFUND_AMOUNT:
        filter = (a, b) =>
          Math.abs(a.TotalRefundAmount) - Math.abs(b.TotalRefundAmount);
        break;
      case HISTORY_HEADERS.REFUND_REASON:
        filter = (a, b) => a.RefundReason.localeCompare(b.RefundReason);
        break;
      default:
        break;
    }
    sortedHistory = sortedHistory.sort(filter);
  } else if (direction[position] === 1) {
    sortedHistory = sortedHistory.reverse();
  }

  return {
    type: SORT_HISTORY,
    direction,
    payload: sortedHistory,
  };
};

export const updateHistory = (
  refundProcessedStartDate,
  refundProcessedEndDate,
  refundRequestStartDate,
  refundRequestEndDate
) => {
  return dispatch => {
    dispatch(updateHistoryStarted());

    return getUpdatedHistory(
      refundProcessedStartDate,
      refundProcessedEndDate,
      refundRequestStartDate,
      refundRequestEndDate
    )
      .then(response => {
        dispatch(updateHistorySuccess(response));
      })
      .catch(error => {
        dispatch(updateHistoryFailure(error));
      });
  };
};

const updateHistoryStarted = () => {
  return {
    type: UPDATE_HISTORY_STARTED,
    loading: true,
    payload: [],
  };
};

const updateHistorySuccess = response => {
  return {
    type: UPDATE_HISTORY_SUCCESS,
    loading: false,
    payload: response.data.data,
  };
};

const updateHistoryFailure = error => {
  return {
    type: UPDATE_HISTORY_FAILURE,
    loading: false,
    payload: error,
  };
};
