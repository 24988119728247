import moment from 'moment';

import {
  GET_HISTORY_STARTED,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAILURE,
  UPDATE_HISTORY_STARTED,
  UPDATE_HISTORY_SUCCESS,
  UPDATE_HISTORY_FAILURE,
  SEARCH_HISTORY,
  SORT_HISTORY,
} from '../actions/historyActions';

const yesterday = moment()
  .subtract(1, 'days')
  .format('YYYY-MM-DD');
const today = moment().format('YYYY-MM-DD');

const historyReducer = (
  state = {
    loading: false,
    history: [],
    allHistory: [],
    sortDirection: [1, -1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    receivedDate: null,
    refundProcessedStartDate: yesterday,
    refundProcessedEndDate: today,
  },

  action
) => {
  switch (action.type) {
    case GET_HISTORY_STARTED:
      return (state = {
        ...state,
        loading: action.loading,
        history: action.payload,
      });
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        history: action.payload,
        allHistory: action.payload,
        loading: false,
        receivedDate: new Date(),
      };

    case GET_HISTORY_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case SORT_HISTORY:
      return {
        ...state,
        history: action.payload,
        sortDirection: action.direction,
      };

    case UPDATE_HISTORY_STARTED:
      return {
        ...state,
        loading: action.loading,
        history: action.payload,
      };

    case UPDATE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        history: action.payload,
        allHistory: action.payload,
      };

    case UPDATE_HISTORY_FAILURE:
      return {
        ...state,
        loading: action.loading,
        error: action.payload,
      };
    case SEARCH_HISTORY:
      return { ...state, history: action.payload };

    default:
      return state;
  }
};

export default historyReducer;
