import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Field, Form, reduxForm, submit } from 'redux-form';
import InputText from '@viking-eng/input-text';
import Select from '@viking-eng/select';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FaRedo, FaDownload } from 'react-icons/fa';
import MultiSelect from '../MultiSelect/MultiSelect';

import { receiveErrors, searchErrors } from '../../actions/errorActions';
import {
  errorProcessedDateOptions,
  processedByOptions,
  bookingStatusOptions,
  paymentTypeOptions,
  triggerModalAttrs,
} from '../../util/constants';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { exportRecordsToCsv } from '../../util/common';
import {
  getRecordStatus,
  getInvoice,
  getCurrency,
  getSalesCCID,
  getName,
  getAcctType,
  getAccountNum,
  getProcessedBy,
  getCruiseID,
  getSailDate,
  getBookingStatus,
  getSource,
  getMessage,
  getProcessedDateForCSV,
} from '../ErrorTable/ErrorTableValues';

import './ErrorTableFilters.scss';

const ErrorTableFilters = ({
  allErrors,
  errors,
  loading,
  receiveErrors,
  lastErrorsRefreshDate,
  handleSubmit,
}) => {
  const [supportError, showSupportError] = useState(false);

  const toggleSupportError = () => {
    showSupportError(!supportError);
  };

  const header = [
    'Record Status',
    'Invoice',
    'Currency',
    'Sales CC ID',
    'Name',
    'Account Type',
    'Account No',
    'Amount',
    'Processed By',
    'Processed Date',
    'Cruise ID',
    'Sail Date',
    'Booking Status',
    'Source',
    'Message',
  ];

  const rows = [
    header,
    ...(errors !== null
      ? errors.map(error => [
          getRecordStatus(error),
          getInvoice(error),
          getCurrency(error),
          getSalesCCID(error),
          getName(error),
          getAcctType(error),
          getAccountNum(error),
          error.Amount,
          getProcessedBy(error),
          getProcessedDateForCSV(error),
          getCruiseID(error),
          getSailDate(error),
          getBookingStatus(error),
          getSource(error),
          getMessage(error),
        ])
      : []),
  ];

  const handleExportToCsv = () => {
    exportRecordsToCsv(errors, rows, toggleSupportError);
  };

  useEffect(() => {
    setTimeout(() => {
      showSupportError(false);
    }, 5000);
  }, [supportError]);

  const processedByUsers =
    allErrors !== null ? allErrors.map(error => error.UserId) : [];
  const processedBy =
    allErrors === [] ? '' : processedByOptions([...new Set(processedByUsers)]);

  return (
    <div className="error-filters-container">
      <Form onSubmit={handleSubmit} className="error-table-filters">
        <Field
          name="errorMessage"
          component={InputText}
          placeholder="Error Message"
        />
        <Field
          name="processedDate"
          component={MultiSelect}
          options={errorProcessedDateOptions}
          placeholder="Processed Date"
          isSearchable={false}
        />
        <Field
          name="processedBy"
          component={MultiSelect}
          options={processedBy}
          placeholder="Processed by"
          isSearchable={false}
        />
        <Field
          name="bookingStatus"
          component={Select}
          options={bookingStatusOptions}
          placeholder="Booking Status"
          isSearchable={false}
        />
        <Field
          name="type"
          component={MultiSelect}
          options={paymentTypeOptions}
          placeholder="Type"
          isSearchable={false}
        />
      </Form>
      {errors && errors.length > 0 && (
        <button className="filter-button" onClick={handleExportToCsv}>
          <FaDownload />
          <div
            className={classNames({
              'filter-button-alert': true,
              show: supportError,
            })}
          >
            Please update your browser to newer version or use another one to be
            able to export CSV.
          </div>
        </button>
      )}
      <button
        className={classNames('filter-button', {
          'spin-animation': loading,
        })}
        {...triggerModalAttrs}
      >
        <FaRedo />
      </button>
      {lastErrorsRefreshDate && (
        <div className="results-msg">
          Last Updated:
          <br />
          {moment(lastErrorsRefreshDate).format('MM/DD/YYYY - LT')}
        </div>
      )}
      <ConfirmModal
        id="confirmRefreshModal"
        onConfirm={() => receiveErrors()}
        title="Errors Refresh"
        subtitle="Are you sure?"
      />
    </div>
  );
};

const mapStateToProps = state => ({
  allErrors: state.errors.allErrors,
  errors: state.errors.errors,
  loading: state.errors.loading,
  lastErrorsRefreshDate: state.errors.receivedDate,
});

const mapDispatchToProps = dispatch => ({
  receiveErrors: () =>
    dispatch(receiveErrors()).then(() => {
      dispatch(submit('errorTableFiltersForm'));
    }),
});

const onSubmit = (
  { processedDate, processedBy, bookingStatus, type },
  dispatch,
  { allErrors }
) => {
  return dispatch(
    searchErrors(allErrors, {
      processedDate,
      processedBy,
      bookingStatus,
      type,
    })
  );
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'errorTableFiltersForm',
    onSubmit,
    onChange: onSubmit,
  })
);

export default enhance(ErrorTableFilters);
