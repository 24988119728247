import React from 'react';
import Button from '@viking-eng/button';
import Modal from '@viking-eng/modal';

import * as PropTypes from 'prop-types';

import { formatNumber } from '../../util/common';
import { renderItem } from '../../util/view';

import './InfoModal.scss';

const InfoModal = ({
  id,
  confirmText,
  onConfirm,
  approvedCount,
  approvedSum,
  declinedCount,
  declinedSum,
}) => {
  const onInfoModal = event => {
    window.$(`#${id}`).modal('hide');
    if (onConfirm) {
      onConfirm(event);
    }
  };

  return (
    <Modal id={id}>
      <div className="info-modal">
        <div className="content">
          <div className="row summary-row justify-content-center">
            <div className="col-sm">
              <div className="details-column">
                {renderItem(
                  'Number of refunds approved',
                  approvedCount,
                  false,
                  true
                )}
              </div>
            </div>
          </div>
          <div className="row summary-row justify-content-center">
            <div className="col-sm">
              <div className="details-column">
                {renderItem(
                  'Total refund Amount approved',
                  `$ ${formatNumber(approvedSum)}`,
                  false,
                  true
                )}
              </div>
            </div>
          </div>
          <div className="row summary-row justify-content-center top-spacing">
            <div className="col-sm">
              <div className="details-column">
                {renderItem(
                  'Number of refunds rejected',
                  declinedCount,
                  true,
                  true
                )}
              </div>
            </div>
          </div>
          <div className="row summary-row justify-content-center">
            <div className="col-sm">
              <div className="details-column">
                {renderItem(
                  'Total refund Amount rejected',
                  `$ ${formatNumber(declinedSum)}`,
                  true,
                  true
                )}
              </div>
            </div>
          </div>
          <div className="row buttons-row justify-content-center">
            <Button
              className="hover-btn"
              appearance="secondary-blue"
              onButtonClick={onInfoModal}
            >
              {confirmText}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

InfoModal.propTypes = {
  id: PropTypes.string,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  approvedCount: PropTypes.number,
  approvedSum: PropTypes.number,
  declinedCount: PropTypes.number,
  declinedSum: PropTypes.number,
};

InfoModal.defaultProps = {
  confirmText: 'OK',
  approvedCount: 0,
  approvedSum: 0,
  declinedCount: 0,
  declinedSum: 0,
};

export default InfoModal;
