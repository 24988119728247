import { formatNumber, formatDate, formatDateTime } from '../../util/common';

import moment from 'moment';

export const getRecordStatus = history => {
  if (history.RequestStatus === 'X') {
    return 'Canceled';
  } else if (history.RequestStatus === 'F') {
    return 'Completed';
  } else if (history.RequestStatus === 'D') {
    return 'Declined';
  }
};
export const getInvoice = history => history.Invoice;
export const getCurrency = history => history.Currency;
export const getReqDate = history => formatDate(history.RequestDate);
export const getReqDateForCSV = history => formatDateTime(history.RequestDate);
export const getProcessedDate = history => {
  if (history.RequestStatus === 'X') {
    return 'N/A';
  } else {
    return formatDate(history.ProcessedDate);
  }
};
export const getProcessedDateForCSV = history => {
  if (history.RequestStatus === 'X') {
    return 'N/A';
  } else {
    return formatDateTime(history.ProcessedDate);
  }
};
export const getAgeDate = history => {
  let ageDate =
    moment(history.RequestDate).diff(moment(history.ProcessedDate), 'days') *
    -1;
  if (history.RequestStatus === 'X') {
    return 'N/A';
  } else {
    if (ageDate > 30 && ageDate <= 60) {
      ageDate = '> 30';
    } else if (ageDate > 60) {
      ageDate = '> 60';
    }
    return `${ageDate} Days`;
  }
};
export const getProcessedBy = history => history.ModifiedUserId;
export const getProcessedStatus = history =>
  history.ProcessedAPI ? 'Yes' : 'No';
export const getSource = history => history.Source;
export const getBookingStatus = history => history.StatusText;
export const getCruiseID = history => history.Cruise;
export const getSailDate = history => formatDate(history.SailDate);
export const getName = history =>
  `${history.FirstName.toUpperCase()} ${history.LastName.toUpperCase()}`;
export const getAcctType = history => history.RefundType;
export const getAccountNum = history => history.AccountNumber.slice(-6);
export const getTotalRefundAmt = history =>
  history.TotalRefundAmount >= 0
    ? `$ ${formatNumber(history.TotalRefundAmount)}`
    : `$ ${formatNumber(history.TotalRefundAmount * -1)}`;
export const getRefundReason = history => history.RefundReason;
export const getUpdatedDateTime = history =>
  formatDateTime(history.ModifiedDate);
