import {
  GET_BOOKINGS_STARTED,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAILURE,
  REFRESH_BOOKINGS_STARTED,
  REFRESH_BOOKINGS_SUCCESS,
  REFRESH_BOOKINGS_FAILURE,
  UPDATE_ICON_STATUS,
  UPDATE_GROUP_ICON_STATUS,
  SUBMIT_BOOKINGS_STARTED,
  SUBMIT_BOOKINGS_SUCCESS,
  SUBMIT_BOOKINGS_FAILURE,
  HIDE_MODAL,
  FILTER,
  SEARCH_BOOKINGS,
  SORT_BOOKINGS,
  SUPERVISOR_REVIEW,
  LOCK_MULTIPLE_ROWS,
  APPROVE_MULTIPLE_ROWS,
  DECLINE_MULTIPLE_ROWS,
} from '../actions/bookingActions';

import {
  GET_COMMENTS_STARTED,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAILURE,
} from '../actions/commentsActions';

import { BOOKING_STATUS, APPROVAL_STATUS } from '../util/constants';

import { isUndefinedOrNull, checkSystemReasons } from '../util/common';

const bookingsReducer = (
  state = {
    loading: false,
    commentsLoading: false,
    bookings: [],
    allBookings: [],
    comments: [],
    sortDirection: [1, 1, 1, -1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    receivedDate: null,
    updatedDate: null,
    submitResponseModal: false,
    submitResponse: {
      refunds_approved_count: 0,
      refunds_approved_amount: 0,
      refunds_declined_count: 0,
      refunds_declined_amount: 0,
    },
    submitError: null,
    submitErrorModal: false,
  },
  action
) => {
  switch (action.type) {
    case 'GET_MATCHED':
      return { ...state, ...action.payload };

    case GET_BOOKINGS_STARTED:
      return (state = {
        ...state,
        loading: action.loading,
        bookings: action.payload,
        allBookings: action.payload,
      });

    case GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: action.payload,
        allBookings: action.payload,
        loading: false,
        receivedDate: new Date(),
        updatedDate: new Date(),
      };

    case GET_BOOKINGS_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case REFRESH_BOOKINGS_STARTED:
      return state;

    case REFRESH_BOOKINGS_SUCCESS:
      const { allBookings, bookings } = state;

      if (action.payload.length === 0) {
        return state;
      } else {
        action.payload.forEach(b => {
          const allBookingsIndex = allBookings.findIndex(
            booking => b.RefundId === booking.RefundId
          );

          const bookingsIndex = bookings.findIndex(
            booking => b.RefundId === booking.RefundId
          );

          const systemReason = checkSystemReasons(bookings[bookingsIndex]);

          if (b.has_comments === true) {
            allBookings[allBookingsIndex].has_comments = b.has_comments;
            allBookings[allBookingsIndex].markedNeedsReview =
              b.marked_needs_review;

            bookings[bookingsIndex].has_comments = b.has_comments;
            bookings[bookingsIndex].markedNeedsReview = b.marked_needs_review;
          }

          if (!isUndefinedOrNull(b.markedNeedsReview)) {
            allBookings[allBookingsIndex].approvalStatus =
              APPROVAL_STATUS.DECLINED;
            allBookings[allBookingsIndex].markedNeedsReview =
              b.marked_needs_review;

            bookings[bookingsIndex].systemReason.push(
              BOOKING_STATUS.NEEDS_SUP_REVIEW
            );
            bookings[bookingsIndex].approvalStatus = APPROVAL_STATUS.DECLINED;
            bookings[bookingsIndex].markedNeedsReview = b.marked_needs_review;
          } else {
            allBookings[allBookingsIndex].markedNeedsReview =
              b.marked_needs_review;

            bookings[bookingsIndex].systemReason = systemReason;
            bookings[bookingsIndex].markedNeedsReview = b.marked_needs_review;
          }

          if (b.inUseBy !== undefined) {
            allBookings[allBookingsIndex].inUseBy = b.inUseBy;
            allBookings[allBookingsIndex].markedNeedsReview =
              b.marked_needs_review;

            bookings[bookingsIndex].inUseBy = b.inUseBy;
            bookings[bookingsIndex].markedNeedsReview = b.marked_needs_review;
            bookings[bookingsIndex].systemReason = systemReason;
          }

          if (!isUndefinedOrNull(b.marked_needs_review)) {
            bookings[bookingsIndex].systemReason.push(
              BOOKING_STATUS.NEEDS_SUP_REVIEW
            );
          }
        });
        return {
          ...state,
          allBookings: [...allBookings],
          bookings: [...bookings],
          updatedDate: new Date(),
        };
      }

    case REFRESH_BOOKINGS_FAILURE:
      return { ...state, error: action.payload };

    case GET_COMMENTS_STARTED:
      return (state = { ...state, commentsLoading: true });

    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: action.payload,
        commentsLoading: false,
      };

    case GET_COMMENTS_FAILURE:
      return { ...state, error: action.payload, commentsLoading: false };

    case UPDATE_ICON_STATUS:
      let updatedBookings = action.payload;
      let updatedBookingIndex = state.allBookings.findIndex(
        b => b.RefundId === action.RefundId
      );
      let updatedBooking = updatedBookings.find(
        b => b.RefundId === action.RefundId
      );

      state.allBookings[updatedBookingIndex] = updatedBooking;

      return {
        ...state,
        bookings: updatedBookings,
        allBookings: [...state.allBookings],
      };

    case UPDATE_GROUP_ICON_STATUS:
      let updatedGroupBookings = action.payload;

      function getAllIndexes(arr, val) {
        var indexes = [],
          i;
        for (i = 0; i < arr.length; i++)
          if (arr[i].Invoice === val) indexes.push(i);
        return indexes;
      }

      let updatedGroupBookingIndexes = getAllIndexes(
        state.allBookings,
        action.invoice
      );

      if (updatedGroupBookings.length === state.allBookings.length) {
        updatedGroupBookingIndexes.forEach(
          idx => (state.allBookings[idx] = updatedGroupBookings[idx])
        );
      } else {
        updatedGroupBookingIndexes.forEach(idx => {
          updatedGroupBookings.forEach(booking => {
            if (booking.RefundId === state.allBookings[idx].RefundId) {
              state.allBookings[idx] = booking;
            }
          });
        });
      }
      return {
        ...state,
        bookings: [...updatedGroupBookings],
        allBookings: [...state.allBookings],
      };

    case SUBMIT_BOOKINGS_STARTED:
      return state;

    case SUBMIT_BOOKINGS_SUCCESS:
      return {
        ...state,
        submitResponse: action.payload,
        submitResponseModal: true,
      };

    case SUBMIT_BOOKINGS_FAILURE:
      return { ...state, submitError: action.payload, submitErrorModal: true };

    case HIDE_MODAL:
      return { ...state, [action.payload]: false };

    case FILTER:
      return { ...state, ...action.payload };

    case SEARCH_BOOKINGS:
      return { ...state, bookings: action.payload };

    case SORT_BOOKINGS:
      return {
        ...state,
        bookings: action.payload,
        sortDirection: action.direction,
      };

    case SUPERVISOR_REVIEW:
      return { ...state, ...action.payload };

    case LOCK_MULTIPLE_ROWS:
      return { ...state, bookings: [...action.payload] };

    case APPROVE_MULTIPLE_ROWS:
      return { ...state, bookings: [...action.payload] };

    case DECLINE_MULTIPLE_ROWS:
      return { ...state, bookings: [...action.payload] };

    default:
      return state;
  }
};

export default bookingsReducer;
