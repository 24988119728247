import { AuthenticationContext } from 'react-adal';
import { jwtDecode } from 'jwt-decode';

const adalConfig = {
  tenant: process.env.REACT_APP_ADAL_TENANT_ID,
  clientId: process.env.REACT_APP_ADAL_CLIENT_ID,
  redirectUri: process.env.REACT_APP_ADAL_REDIRECT_URI,
  localLoginUrl: '/',
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);
export const getToken = () => authContext.getCachedToken(adalConfig.clientId);
export const userLogout = () => authContext.logOut();

export const getUserInfo = () => {
  const token = jwtDecode(getToken());
  const name = token.name;
  const isSupervisor = token.roles ? true : false;

  // Default to CROSALES (in lower env only)
  const evolutionId = token.evolutionid && token.evolutionid.toUpperCase();

  return {
    username: name,
    evolutionId: evolutionId,
    isSupervisor: isSupervisor,
  };
};
