import React from 'react';
import Button from '@viking-eng/button';
import Modal from '@viking-eng/modal';
import Heading from '@viking-eng/heading';
import * as PropTypes from 'prop-types';

import { formatNumber } from '../../util/common';
import { renderItem } from '../../util/view';

import './ConfirmModal.scss';

const ConfirmModal = ({
  id,
  title,
  subtitle,
  cancelText,
  onCancel,
  confirmText,
  onConfirm,
  approvedCount,
  approvedSum,
}) => {
  const onCancelModal = event => {
    window.$(`#${id}`).modal('hide');
    if (onCancel) {
      onCancel(event);
    }
  };

  const onConfirmModal = event => {
    window.$(`#${id}`).modal('hide');
    if (onConfirm) {
      onConfirm(event);
    }
  };

  return (
    <Modal id={id}>
      {title && (
        <div className="confirm-modal">
          <div className="content">
            <Heading subTitle={subtitle} title={title} />
            <div className="row summary-row justify-content-center">
              <div className="col-sm">
                <div className="details-column">
                  {approvedCount > 0 &&
                    renderItem('Number of refunds', approvedCount, true)}
                </div>
              </div>
            </div>
            <div className="row summary-row justify-content-center">
              <div className="col-sm">
                <div className="details-column">
                  {approvedSum > 0 &&
                    renderItem(
                      'Total refund Amount',
                      `($ ${formatNumber(approvedSum)})`,
                      true
                    )}
                </div>
              </div>
            </div>
            <div className="row buttons-row justify-content-center">
              <div className="btn-cancel">
                <Button
                  className="hover-btn"
                  appearance="secondary-gray"
                  onButtonClick={onCancelModal}
                >
                  {cancelText}
                </Button>
              </div>
              <Button
                className="hover-btn"
                appearance="secondary-blue"
                onButtonClick={onConfirmModal}
                disabled={approvedCount === 0 || approvedSum === 0}
              >
                {confirmText}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

ConfirmModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
};

ConfirmModal.defaultProps = {
  cancelText: 'CANCEL',
  confirmText: 'YES',
};

export default ConfirmModal;
