import axios from 'axios';
import { getBookingsToSubmit, getValueBasedOnObject } from '../util/common';
import { BOOKING_PROPERTIES } from '../util/constants';

const { REFUND_ID } = BOOKING_PROPERTIES;

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getBookings = () => axios.get(`${BASE_URL}/bookings`);

export const getRefreshBookings = date =>
  axios.post(`${BASE_URL}/refresh-bookings`, {
    date: date,
  });

export const updateInUse = (booking, user) =>
  axios.post(`${BASE_URL}/update-in-use`, {
    refund_id: getValueBasedOnObject(booking, REFUND_ID),
    user: user,
  });

export const updateNeedsReview = (
  booking,
  user,
  isSupervisor,
  removeNeedsReview
) => {
  return axios.post(`${BASE_URL}/update-needs-review`, {
    refund_id: booking.RefundId,
    user: user,
    is_supervisor: isSupervisor,
    removeNeedsReview: removeNeedsReview,
  });
};

export const getComments = RefundId =>
  axios.get(`${BASE_URL}/comments/${RefundId}`);

export const addComment = (RefundId, message, userId) =>
  axios.post(`${BASE_URL}/comments/${RefundId}`, {
    refund_id: RefundId,
    message: message,
    username: userId,
  });

export const submitRefunds = (bookings, user) => {
  const bookingsToSubmit = getBookingsToSubmit(bookings);
  return axios.post(`${BASE_URL}/submit-bookings`, bookingsToSubmit, {
    headers: {
      userid: user,
    },
  });
};

export const getErrors = () => axios.get(`${BASE_URL}/errors`);

export const getHistory = () => axios.post(`${BASE_URL}/history`);

export const getUpdatedHistory = (
  refundProcessedStartDate,
  refundProcessedEndDate,
  refundRequestStartDate,
  refundRequestEndDate
) => {
  refundProcessedStartDate =
    refundProcessedStartDate === '' ? undefined : refundProcessedStartDate;
  refundProcessedEndDate =
    refundProcessedEndDate === '' ? undefined : refundProcessedEndDate;

  return axios.post(`${BASE_URL}/history`, {
    refundProcessedStartDate,
    refundProcessedEndDate,
    refundRequestStartDate,
    refundRequestEndDate,
  });
};
