import React from 'react';
import { components } from 'react-select';

const SingleValue = props => {
  const {
    selectProps: {
      value: { label },
      valueSeparator,
    },
  } = props;

  const value = Array.isArray(label) ? label.join(valueSeparator) : label;

  return <components.SingleValue {...props}>{value}</components.SingleValue>;
};

export default SingleValue;
