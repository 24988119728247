import moment from 'moment';
import {
  formatNumber,
  formatDate,
  isUndefinedOrNull,
  formatDateTime,
  getRefundAmt,
  getValueBasedOnObject,
} from '../../util/common';
import {
  APPROVAL_STATUS_FOR_EXPORT,
  BOOKING_PROPERTIES,
} from '../../util/constants';

const {
  REQ_DATE,
  FIRST_NAME,
  LAST_NAME,
  ACCT_TYPE,
  ACCT_NUM,
  DISC_AMT,
  REFUND_REASON,
  REQ_USER_ID,
  UP_DATE,
  REQ_STATUS,
  REF_COMMENTS,
  METHOD,
} = BOOKING_PROPERTIES;

export const getApprovalStatus = booking => booking.approvalStatus;
export const getNeedsReview = booking =>
  !isUndefinedOrNull(booking.markedNeedsReview);
export const getInvoiceNumber = booking => booking.Invoice;
export const getAgingDateDays = booking => {
  const requestDate = getValueBasedOnObject(booking, REQ_DATE);
  return moment(requestDate).diff(moment(), 'days') * -1;
};
export const getName = booking => {
  const firstName = getValueBasedOnObject(booking, FIRST_NAME);
  const lastName = getValueBasedOnObject(booking, LAST_NAME);
  return `${firstName.toUpperCase()} ${lastName.toUpperCase()}`;
};
export const getAcctType = booking => getValueBasedOnObject(booking, ACCT_TYPE);
export const getAcctNum = booking =>
  getValueBasedOnObject(booking, ACCT_NUM).slice(-6);
export const getRefund = booking => {
  let refundAmt = getRefundAmt(booking);
  return `($ ${formatNumber(Math.abs(refundAmt))})`;
};
export const getExportRefund = booking => {
  let refundAmt = getRefundAmt(booking);
  return Math.abs(refundAmt) * -1;
};
export const getReqRefund = booking => {
  let refundAmt = getRefundAmt(booking);
  return `($ ${formatNumber(Math.abs(refundAmt))})`;
};
export const getGroupRefund = (bookingStatus, refunds) => {
  return `($ ${formatNumber(
    refunds.reduce((sum, r) => {
      const refundAmt =
        bookingStatus === 'K'
          ? Math.abs(r.Amount)
          : Math.abs(r.TotalRefundAmount);
      return sum + refundAmt;
    }, 0)
  )})`;
};
export const getDiscAmt = booking =>
  `($ ${formatNumber(getValueBasedOnObject(booking, DISC_AMT))})`;
export const getPaid = booking => `$ ${formatNumber(booking.RecAmt)}`;
export const getGrossPrice = booking => `$ ${formatNumber(booking.GrossPrice)}`;
export const getGrossOutstanding = ({ Gross_Outstanding }) =>
  Gross_Outstanding < 0
    ? `($ ${formatNumber(Gross_Outstanding * -1)})`
    : `($ ${formatNumber(Gross_Outstanding)})`;
export const getNetOutstanding = ({ Net_Outstanding }) =>
  Net_Outstanding < 0
    ? `($ ${formatNumber(Net_Outstanding * -1)})`
    : `($ ${formatNumber(Net_Outstanding)})`;
export const getSystemReason = booking =>
  booking.systemReasons.filter((v, i, a) => a.indexOf(v) === i).join(', ');
export const getSystemReasonExport = booking =>
  booking.systemReasons.filter((v, i, a) => a.indexOf(v) === i).join('; ');
export const getCruiseID = booking => booking.Cruise;
export const getBookingStatus = booking => booking.BookingStatusText;
export const getShip = booking => booking.Ship;
export const getVoyageCount = booking => booking.Voyages;
export const getSailDate = booking => formatDate(booking.SailDate);
export const getDaystoEmbarkation = booking =>
  moment(getSailDate(booking), 'MM/DD/YYYY')
    .startOf('day')
    .diff(moment().startOf('day'), 'days');
export const getSource = booking => booking.Source;
export const getShortpayAllow = booking => booking.ShortPayAllow;
export const getCurrency = booking => booking.Currency;
export const getSaleDate = booking => formatDate(booking.SaleDate);
export const getSaleDateForCSV = booking => formatDateTime(booking.SaleDate);
export const getBalDueDate = booking => formatDate(booking.BalanceDate);
export const getCxlDate = booking =>
  booking.CancelDate && formatDate(booking.CancelDate);
export const getDaysFromDeparture = booking =>
  booking.CancelDate &&
  moment
    .utc(booking.GuestDepartureDate)
    .diff(moment(booking.CancelDate), 'days');
export const getCxlPenalty = booking => booking.CancelPenaltyPercent;
export const getCxlReason = booking => booking.CancelReason;
export const getCommission = ({
  BookingStatus,
  TotalCancelCommission,
  TotalCommission,
}) =>
  BookingStatus === 'K'
    ? `($ ${formatNumber(TotalCancelCommission)})`
    : `($ ${formatNumber(TotalCommission)})`;
export const getRefundReason = booking =>
  getValueBasedOnObject(booking, REFUND_REASON);
export const getReqBy = booking => getValueBasedOnObject(booking, REQ_USER_ID);
export const getReqDate = booking =>
  formatDate(getValueBasedOnObject(booking, REQ_DATE));
export const getReqDateForCSV = booking =>
  formatDateTime(getValueBasedOnObject(booking, REQ_DATE));
export const unformattedUpdDate = booking =>
  getValueBasedOnObject(booking, UP_DATE);
export const getUpdDate = booking =>
  unformattedUpdDate(booking) ? formatDate(unformattedUpdDate(booking)) : '';
export const getUpdDateForCSV = booking =>
  unformattedUpdDate(booking)
    ? formatDateTime(unformattedUpdDate(booking))
    : '';
export const getReqStatus = booking =>
  getValueBasedOnObject(booking, REQ_STATUS);
export const getRefundComments = booking =>
  getValueBasedOnObject(booking, REF_COMMENTS);
export const getHasComments = booking => booking.has_comments;
export const changeApprovalStatusForCSV = booking =>
  APPROVAL_STATUS_FOR_EXPORT[booking.approvalStatus];
export const getInUseStatus = (booking, user) => {
  let color;
  if (isUndefinedOrNull(booking.inUseBy)) {
    color = 'gray';
  } else {
    if (booking.inUseBy === user.evolutionId) {
      color = 'green';
    } else {
      color = 'black';
    }
  }
  return color;
};
export const getInUseBy = booking => booking.inUseBy;
export const getLocked = booking => booking.inUseBy;
export const getMethod = booking => getValueBasedOnObject(booking, METHOD);
