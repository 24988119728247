import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import { receiveHistory } from '../../actions/historyActions';
import HistoryTableFilters from '../../components/HistoryTableFilters/HistoryTableFilters';

import HistoryTable from '../../components/HistoryTable/HistoryTable';
import { getUserInfo } from '../../util/adalConfig';
import HistoryTableParams from '../../components/HistoryTable/HistoryTableParams/HistoryTableParams';

class HistoryPage extends Component {
  constructor(props) {
    super(props);

    const { handleLogin } = this.props;
    handleLogin(getUserInfo());
  }

  async componentDidMount() {
    const { receiveHistory } = this.props;
    receiveHistory();
  }

  render() {
    return (
      <div className="col">
        <h5>Historical Records</h5>
        <HistoryTableParams />
        <HistoryTableFilters />
        <HistoryTable />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  receiveHistory: () => dispatch(receiveHistory()),
  handleLogin: user => dispatch(loginUser(user)),
});

export default connect(
  null,
  mapDispatchToProps
)(HistoryPage);
