import React from 'react';
import classNames from 'classnames';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import InfoModal from '../InfoModal/InfoModal';
import ErrorModal from '../ErrorModal/ErrorModal';
import Button from '@viking-eng/button';
import {
  FaUserFriends,
  FaCheck,
  FaTimes,
  FaCaretDown,
  FaCaretUp,
  FaLockOpen,
  FaLock,
} from 'react-icons/fa';
import { MdComment } from 'react-icons/md';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import {
  receiveBookings,
  sortBookings,
  updateIconStatus,
  updateGroupIconStatus,
  submitBookings,
  hideModal,
  lockMultipleRows,
  unlockMultipleRows,
  approveMultipleRows,
  declineMultipleRows,
} from '../../actions/bookingActions';
import {
  APPROVAL_STATUS,
  BOOKING_HEADERS,
  BOOKINGS_SORT_POSITION,
  BOOKING_PROPERTIES,
  invoiceNumberModalAttrs,
  confirmSubmitModalAttrs,
  submitResponseModalAttrs,
  errorModalAttrs,
} from '../../util/constants';
import BookingDetailsModal from '../BookingDetailsModal/BookingDetailsModal';
import {
  getAcctNum,
  getAcctType,
  getAgingDateDays,
  getApprovalStatus,
  getCurrency,
  getGrossOutstanding,
  getGrossPrice,
  getInvoiceNumber,
  getName,
  getNeedsReview,
  getPaid,
  getRefund,
  getReqDate,
  getSystemReason,
  getDaystoEmbarkation,
  getHasComments,
  getInUseStatus,
} from './RefundTableValues';
import { fetchComments } from '../../actions/commentsActions';
import BookingGroup from './BookingGroup/BookingGroup';
import {
  isBookingGroup,
  isUndefinedOrNull,
  getValueBasedOnObject,
  getApprovedBookings,
  getRefundAmt,
  getGroupRefAmt,
} from '../../util/common';
import './RefundTable.scss';

const { REFUND_ID } = BOOKING_PROPERTIES;
const { APPROVED, DECLINED, PENDING } = APPROVAL_STATUS;

class RefundTable extends React.Component {
  state = {
    activeBooking: {},
    shiftKey: false,
    firstIndex: null,
    lastIndex: null,
    firstClickColumn: null,
  };

  openBookingDetailsModal = (
    refundBooking,
    allBookingDetails = refundBooking
  ) => {
    const { fetchComments } = this.props;
    this.setState(state => ({
      ...state,
      activeBooking: allBookingDetails,
    }));
    fetchComments(getValueBasedOnObject(refundBooking, REFUND_ID));
  };

  handleShiftPress = e => {
    if (e.keyCode === 16 && this.state.shiftKey === false) {
      this.setState({ shiftKey: true });
    }
  };

  handleShiftRelease = e => {
    if (e.keyCode === 16) {
      this.setState({ shiftKey: false });
    }
  };

  handleMultiClick = e => {
    let index = e.target.dataset.index;
    let idx1, idx2;
    let column = e.target.dataset.column;
    let inUse = e.target.dataset.user;
    if (column) {
      if (this.state.shiftKey === false && index !== undefined) {
        this.setState({
          firstIndex: index,
          firstClickColumn: column,
          firstClickLock: inUse,
        });
        idx1 = index;
      } else if (this.state.shiftKey === true && index !== undefined) {
        this.setState({ lastIndex: index });
        idx1 = this.state.firstIndex;
        idx2 = index;
      }
      if (idx1 && idx2) {
        if (column === BOOKING_HEADERS.IN_USE) {
          if (isUndefinedOrNull(this.state.firstClickLock)) {
            this.props.handleMultipleRowLock(
              idx1,
              idx2,
              this.props.bookings,
              this.props.user
            );
          } else {
            this.props.handleMultipleRowUnlock(
              idx1,
              idx2,
              this.props.bookings,
              this.props.user
            );
          }
        } else if (
          this.state.firstClickColumn === DECLINED ||
          this.state.firstClickColumn === PENDING
        ) {
          this.props.handleMultipleRowApprove(
            idx1,
            idx2,
            this.props.bookings,
            this.props.user
          );
        } else if (this.state.firstClickColumn === APPROVED) {
          this.props.handleMultipleRowDecline(
            idx1,
            idx2,
            this.props.bookings,
            this.props.user
          );
        }
      }
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleShiftPress);
    document.addEventListener('keyup', this.handleShiftRelease);
  }

  componentDidUpdate() {
    // Refactor
    const { submitResponseModal, submitErrorModal, hideModal } = this.props;

    if (submitResponseModal) {
      window.jQuery('#submitResponseModalButton').click();
      hideModal('submitResponseModal');
    }

    if (submitErrorModal) {
      window.jQuery('#errorModalButton').click();
      hideModal('submitErrorModal');
    }
  }

  render() {
    const {
      loading,
      bookings,
      allBookings,
      receiveBookings,
      submitBookings,
      submitResponse,
      handleSort,
      handleIconUpdate,
      handleGroupIconUpdate,
      sortDirection,
      user,
      submitError,
    } = this.props;
    const { activeBooking } = this.state;

    const getClickableIconAttrs = (
      header,
      bookings,
      RefundId,
      color,
      user
    ) => ({
      header: { header },
      className: `clickable clickable-icon color-viking-${color}`,
      id: { RefundId },
      user: { user },
      onClick: () => handleIconUpdate(header, bookings, RefundId, user),
    });

    const getSortAttrs = (header, position) => ({
      onClick: () =>
        handleSort(bookings, header, position, sortDirection, user.evolutionId),
      className: 'clickable',
    });

    const handleSubmitRefunds = user => {
      const approvedBookings = getApprovedBookings(bookings);
      submitBookings(approvedBookings, user);
    };

    const sortIcon = position =>
      sortDirection[position] === 1 ? <FaCaretDown /> : <FaCaretUp />;

    const approvedCount = bookings.reduce(
      (acc, b) =>
        b.approvalStatus === APPROVAL_STATUS.PENDING ||
        b.approvalStatus === APPROVAL_STATUS.APPROVED
          ? ++acc
          : acc,
      0
    );

    const approvedCountToSubmit = bookings.reduce(
      (acc, b) => (b.approvalStatus === APPROVAL_STATUS.APPROVED ? ++acc : acc),
      0
    );

    const getApprovedSum = bookings => {
      const approvedBookings = getApprovedBookings(bookings);
      const refundAmounts = [];
      approvedBookings.forEach(booking => {
        if (isBookingGroup(booking)) {
          refundAmounts.push(getGroupRefAmt(booking));
        } else {
          refundAmounts.push(getRefundAmt(booking));
        }
      });
      return refundAmounts.reduce((acc, b) => (acc += Math.abs(b)), 0);
    };

    const approvedSum = getApprovedSum(bookings);

    const lockedByCurrentUserCount = bookings.filter(
      b => b.inUseBy === user.evolutionId
    ).length;

    return (
      <div className="refund-table">
        {bookings.length ? (
          <React.Fragment>
            <BookingDetailsModal
              id="bookingDetailsModal"
              user={user}
              booking={activeBooking}
            />
            <InfoModal
              id="submitResponseModal"
              confirmText="OK"
              onConfirm={() => receiveBookings()}
              approvedCount={submitResponse.refunds_approved_count}
              approvedSum={submitResponse.refunds_approved_amount}
              declinedCount={submitResponse.refunds_declined_count}
              declinedSum={submitResponse.refunds_declined_amount}
            />
            <button
              id="submitResponseModalButton"
              className="hidden-button"
              {...submitResponseModalAttrs}
            />
            <ErrorModal id="errorModal" subtitle={submitError} />
            <button
              id="errorModalButton"
              className="hidden-button"
              {...errorModalAttrs}
            />
            <div className="header-messages">
              <p className="results-msg">
                Found {bookings.length} of {allBookings.length} Requests (
                {approvedCount} ready to go)
              </p>
            </div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.STATUS,
                      BOOKINGS_SORT_POSITION.STATUS
                    )}
                  >
                    <FaCheck />
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.REVIEW,
                      BOOKINGS_SORT_POSITION.REVIEW
                    )}
                  >
                    <FaUserFriends /> {sortIcon(BOOKINGS_SORT_POSITION.REVIEW)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.COMMENTS,
                      BOOKINGS_SORT_POSITION.COMMENTS
                    )}
                  >
                    <MdComment /> {sortIcon(BOOKINGS_SORT_POSITION.COMMENTS)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.INVOICE,
                      BOOKINGS_SORT_POSITION.INVOICE
                    )}
                  >
                    Invoice {sortIcon(BOOKINGS_SORT_POSITION.INVOICE)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.CURRENCY,
                      BOOKINGS_SORT_POSITION.CURRENCY
                    )}
                  >
                    Currency {sortIcon(BOOKINGS_SORT_POSITION.CURRENCY)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.EMBARK,
                      BOOKINGS_SORT_POSITION.EMBARK
                    )}
                  >
                    Days to Embark {sortIcon(BOOKINGS_SORT_POSITION.EMBARK)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.REQ_DATE,
                      BOOKINGS_SORT_POSITION.REQ_DATE
                    )}
                  >
                    Req Date {sortIcon(BOOKINGS_SORT_POSITION.REQ_DATE)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.AGE_DATE,
                      BOOKINGS_SORT_POSITION.AGE_DATE
                    )}
                  >
                    Age Date {sortIcon(BOOKINGS_SORT_POSITION.AGE_DATE)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.NAME,
                      BOOKINGS_SORT_POSITION.NAME
                    )}
                  >
                    Name {sortIcon(BOOKINGS_SORT_POSITION.NAME)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.ACCT_TYPE,
                      BOOKINGS_SORT_POSITION.ACCT_TYPE
                    )}
                  >
                    Type {sortIcon(BOOKINGS_SORT_POSITION.ACCT_TYPE)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.ACCT_NUM,
                      BOOKINGS_SORT_POSITION.ACCT_NUM
                    )}
                  >
                    {' '}
                    Acct No {sortIcon(BOOKINGS_SORT_POSITION.ACCT_NUM)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.REFUND,
                      BOOKINGS_SORT_POSITION.REFUND
                    )}
                  >
                    Total RFD {sortIcon(BOOKINGS_SORT_POSITION.REFUND)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.PAID,
                      BOOKINGS_SORT_POSITION.PAID
                    )}
                  >
                    Paid to Date {sortIcon(BOOKINGS_SORT_POSITION.PAID)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.CANCEL,
                      BOOKINGS_SORT_POSITION.CANCEL
                    )}
                  >
                    Gross Price {sortIcon(BOOKINGS_SORT_POSITION.CANCEL)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.GROSS,
                      BOOKINGS_SORT_POSITION.GROSS
                    )}
                  >
                    {' '}
                    Gross Outstanding {sortIcon(BOOKINGS_SORT_POSITION.GROSS)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.Reason,
                      BOOKINGS_SORT_POSITION.Reason
                    )}
                  >
                    System Reason {sortIcon(BOOKINGS_SORT_POSITION.Reason)}
                  </td>
                  <td
                    {...getSortAttrs(
                      BOOKING_HEADERS.IN_USE,
                      BOOKINGS_SORT_POSITION.IN_USE
                    )}
                  >
                    In Use {sortIcon(BOOKINGS_SORT_POSITION.IN_USE)}
                  </td>
                </tr>
              </thead>
              <tbody>
                {bookings.map(booking => (
                  <React.Fragment key={booking.Invoice}>
                    {isBookingGroup(booking) ? (
                      <BookingGroup
                        multiRefundBooking={booking}
                        allBookings={allBookings}
                        bookings={bookings}
                        openBookingDetailsModal={this.openBookingDetailsModal}
                        handleIconUpdate={handleIconUpdate}
                        handleGroupIconUpdate={handleGroupIconUpdate}
                        getClickableIconAttrs={getClickableIconAttrs}
                        user={user}
                        handleMultiClick={this.handleMultiClick}
                        handleShiftPress={this.handleShiftPress}
                        handleShiftRelease={this.handleShiftRelease}
                      />
                    ) : (
                      <tr
                        className={classNames({
                          highlighted:
                            booking.approvalStatus ===
                              APPROVAL_STATUS.APPROVED ||
                            booking.approvalStatus === APPROVAL_STATUS.PENDING,
                        })}
                        onClick={this.handleMultiClick}
                      >
                        {(() => {
                          switch (getApprovalStatus(booking)) {
                            case APPROVAL_STATUS.APPROVED:
                              return (
                                <td
                                  {...getClickableIconAttrs(
                                    BOOKING_HEADERS.APPROVAL_STATUS,
                                    bookings,
                                    booking.Refunds[0].RefundId,
                                    'green',
                                    user
                                  )}
                                  data-index={booking.index}
                                  data-column={APPROVAL_STATUS.APPROVED}
                                >
                                  <FaCheck
                                    className="click-icon"
                                    data-column={APPROVAL_STATUS.APPROVED}
                                    styles={{ pointerEvents: 'none' }}
                                  />
                                </td>
                              );
                            case APPROVAL_STATUS.DECLINED:
                              return (
                                <td
                                  {...getClickableIconAttrs(
                                    BOOKING_HEADERS.APPROVAL_STATUS,
                                    bookings,
                                    booking.Refunds[0].RefundId,
                                    'red',
                                    user
                                  )}
                                  data-index={booking.index}
                                  data-column={APPROVAL_STATUS.DECLINED}
                                >
                                  <FaTimes
                                    className="click-icon"
                                    data-column={APPROVAL_STATUS.DECLINED}
                                    styles={{ pointerEvents: 'none' }}
                                  />
                                </td>
                              );
                            default:
                              return (
                                <td
                                  {...getClickableIconAttrs(
                                    BOOKING_HEADERS.APPROVAL_STATUS,
                                    bookings,
                                    booking.Refunds[0].RefundId,
                                    'gray',
                                    user
                                  )}
                                  data-column={APPROVAL_STATUS.PENDING}
                                  data-index={booking.index}
                                >
                                  <FaCheck
                                    className="click-icon"
                                    data-column={APPROVAL_STATUS.PENDING}
                                    styles={{ pointerEvents: 'none' }}
                                  />
                                </td>
                              );
                          }
                        })()}
                        <td
                          {...getClickableIconAttrs(
                            BOOKING_HEADERS.REVIEW,
                            bookings,
                            booking.Refunds[0].RefundId,
                            getNeedsReview(booking) ? 'red' : 'gray',
                            user
                          )}
                        >
                          <FaUserFriends />
                        </td>
                        <td>
                          {getHasComments(booking) && (
                            <MdComment
                              onClick={() =>
                                this.openBookingDetailsModal(booking)
                              }
                              {...invoiceNumberModalAttrs}
                            />
                          )}
                        </td>
                        <td
                          className="clickable"
                          onClick={() => this.openBookingDetailsModal(booking)}
                          {...invoiceNumberModalAttrs}
                        >
                          {getInvoiceNumber(booking)}
                        </td>
                        <td>{getCurrency(booking)}</td>
                        <td>{getDaystoEmbarkation(booking)} days</td>
                        <td>{getReqDate(booking)}</td>
                        <td>{getAgingDateDays(booking)} days</td>
                        <td className="name-cell">{getName(booking)}</td>
                        <td>{getAcctType(booking)}</td>
                        <td>{getAcctNum(booking)}</td>
                        <td className="money-col negative">
                          {getRefund(booking)}
                        </td>
                        <td className="money-col">{getPaid(booking)}</td>
                        <td className="money-col">{getGrossPrice(booking)}</td>
                        <td className="money-col negative">
                          {getGrossOutstanding(booking)}
                        </td>
                        <td>{getSystemReason(booking)}</td>
                        <td
                          {...getClickableIconAttrs(
                            BOOKING_HEADERS.IN_USE,
                            bookings,
                            booking.Refunds[0].RefundId,
                            getInUseStatus(booking, user),
                            user
                          )}
                          data-index={booking.index}
                          data-column={BOOKING_HEADERS.IN_USE}
                          data-user={booking.inUseBy}
                        >
                          {booking.inUseBy ? (
                            <div
                              data-tip={booking.inUseBy}
                              data-column={BOOKING_HEADERS.IN_USE}
                              data-user={booking.inUseBy}
                              data-index={booking.index}
                            >
                              <FaLock
                                className="click-icon"
                                styles={{ pointerEvents: 'none' }}
                              />
                              <ReactTooltip
                                className="locktext"
                                place="top"
                                type="info"
                                effect="solid"
                              />
                            </div>
                          ) : (
                            <FaLockOpen
                              data-column={BOOKING_HEADERS.IN_USE}
                              data-user={booking.inUseBy}
                              className="click-icon"
                              styles={{ pointerEvents: 'none' }}
                            />
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        ) : (
          <div>
            {loading === true ? (
              <div className="loader-container">
                <Loader type="Oval" color="#456580" width="50" height="50" />
              </div>
            ) : (
              <h5>No results found</h5>
            )}
          </div>
        )}
        <div className="table-buttons">
          <Button
            className="hover-btn"
            appearance="secondary-blue"
            attributes={{ type: 'submit', ...confirmSubmitModalAttrs }}
          >
            Submit For Processing
          </Button>
          {loading !== true ? (
            <div className="locked-selected-bookings">
              <span className="locked-bookings-count">
                Current user Locked: <strong>{lockedByCurrentUserCount}</strong>
              </span>
              <span className="approved-bookings-count">
                Current user Approved: <strong>{approvedCountToSubmit}</strong>
              </span>
            </div>
          ) : null}
          <ConfirmModal
            id="confirmSubmitModal"
            cancelText="NO, CANCEL"
            confirmText="YES, SUBMIT"
            approvedCount={approvedCountToSubmit}
            approvedSum={approvedSum}
            onConfirm={() => handleSubmitRefunds(user)}
            title="Are you sure you want to submit?"
            subtitle=""
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.bookings.loading,
  sortDirection: state.bookings.sortDirection,
  user: state.auth.user,
  submitResponse: state.bookings.submitResponse,
  submitResponseModal: state.bookings.submitResponseModal,
  submitError: state.bookings.submitError,
  submitErrorModal: state.bookings.submitErrorModal,
});

const mapDispatchToProps = dispatch => ({
  handleSort: (bookings, header, position, sortDirection, user) => {
    dispatch(sortBookings(bookings, header, position, sortDirection, user));
  },
  handleIconUpdate: (header, bookings, RefundId, user) => {
    dispatch(updateIconStatus(header, bookings, RefundId, user));
  },
  handleGroupIconUpdate: (header, bookings, invoice, user) => {
    dispatch(updateGroupIconStatus(header, bookings, invoice, user));
  },
  fetchComments: refundId => dispatch(fetchComments(refundId)),
  receiveBookings: () =>
    dispatch(receiveBookings()).then(() => {
      dispatch(submit('topPageFiltersForm'));
    }),
  submitBookings: (bookings, user) => dispatch(submitBookings(bookings, user)),
  hideModal: modal => dispatch(hideModal(modal)),
  handleMultipleRowLock: (idx1, idx2, bookings, user) =>
    dispatch(lockMultipleRows(idx1, idx2, bookings, user)),
  handleMultipleRowUnlock: (idx1, idx2, bookings, user) =>
    dispatch(unlockMultipleRows(idx1, idx2, bookings, user)),
  handleMultipleRowApprove: (idx1, idx2, bookings, user) =>
    dispatch(approveMultipleRows(idx1, idx2, bookings, user)),
  handleMultipleRowDecline: (idx1, idx2, bookings, user) =>
    dispatch(declineMultipleRows(idx1, idx2, bookings, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundTable);
