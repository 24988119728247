import {
  GET_ERRORS_STARTED,
  GET_ERRORS_SUCCESS,
  GET_ERRORS_FAILURE,
} from '../actions/errorActions';

const errorReducer = (
  state = {
    loading: false,
    errors: [],
    allErrors: [],
    sortDirection: [1, -1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    receivedDate: null,
  },

  action
) => {
  switch (action.type) {
    case GET_ERRORS_STARTED:
      return (state = {
        ...state,
        loading: action.loading,
        errors: action.payload,
      });
    case GET_ERRORS_SUCCESS:
      return {
        ...state,
        errors: action.payload,
        allErrors: action.payload,
        loading: false,
        receivedDate: new Date(),
      };

    case GET_ERRORS_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case 'SEARCH_ERRORS':
      return { ...state, errors: action.payload };

    case 'SORT_ERRORS':
      return {
        ...state,
        errors: action.payload,
        sortDirection: action.direction,
      };

    default:
      return state;
  }
};

export default errorReducer;
