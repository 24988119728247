import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';

import bookings from './reducers/bookingsReducer';
import auth from './reducers/authReducer';
import errors from './reducers/errorsReducer';
import history from './reducers/historyReducer';

export default createStore(
  combineReducers({
    bookings,
    auth,
    form: formReducer,
    errors,
    history,
  }),
  compose(
    applyMiddleware(thunk)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  )
);
