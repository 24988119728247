import React, { Suspense } from 'react';

import Header from './components/Header/Header';

import 'normalize.css';
import './App.scss';
import Container from '@viking-eng/container';
import Footer from '@viking-eng/footer';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { BrowserRouter as Router } from 'react-router-dom';

import spinnerUrl from '../images/spinner.svg';
import RefundTablePage from './pages/refundTable/RefundTablePage';
import ErrorPage from './pages/Error/ErrorPage';
import HistoryPage from './pages/History/HistoryPage';

const renderComponent = Component => props => <Component {...props} />;

const suspenseFallback = (
  <div id="loading">
    <img
      id="loadingLogo"
      alt="logo"
      className="loading-fade-in logo"
      src="https://s3-us-west-1.amazonaws.com/olb-images-dev/site_elements/vikinglogowhite.svg"
    />
    <img
      id="loadingSpinner"
      alt=""
      className="loading-spinner"
      src={spinnerUrl}
    />
  </div>
);

const App = ({ location }) => (
  <Router>
    <React.Fragment>
      <Header />
      <div id="content" className="app-content">
        <Container>
          <div className=" row justify-content-md-center">
            <Suspense fallback={suspenseFallback}>
              <Switch location={location}>
                <Route exact path="/null" render={() => <Redirect to="/" />} />
                <Route
                  exact
                  path="/"
                  render={renderComponent(RefundTablePage)}
                />
                <Route
                  exact
                  path="/errors"
                  render={renderComponent(ErrorPage)}
                />
                <Route
                  exact
                  path="/history"
                  render={renderComponent(HistoryPage)}
                />
              </Switch>
            </Suspense>
          </div>
        </Container>
      </div>
      <Footer
        copyrightText={`© Viking, ${new Date().getFullYear()}. All Rights Reserved.`}
      />
    </React.Fragment>
  </Router>
);

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
});

export default connect(mapStateToProps)(App);
