import React from 'react';
import { components } from 'react-select';

import Icon from '../Icon/Icon';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon name="chevron-down" />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
