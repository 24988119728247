// BOOKINGS

export const BOOKING_STATUS = {
  UNSETTLED_ACH: 'Unsettled ACH',
  UNSETTLED_CC: 'Unsettled CC',
  NEEDS_SUP_REVIEW: 'Needs Sup Review',
  CHECK_COMMISSION: 'Check Commission',
  CHECK_ECK_DISCOUNT: 'Check ECK Discount',
  CXL_BOOKING_UNBALANCED: 'Cxl BK Unbalanced',
  DIFFERENT_LAST_NAME: 'Different Payee Last Name',
  DIFFERENT_ADDRESS: 'Different Payee Address',
  GUEST_CXL_EXTENSION: 'Pax Cxl Ext - Please Review',
  SHORTPAY_ALLOW: 'Shortpay Allow',
  OTHER: 'Other',
};

export const BOOKING_TYPE_OPTIONS = {
  DIRECT: 'direct',
  TA: 'ta',
};

export const BOOKING_READINESS_OPTIONS = {
  READY_TO_GO: 'ready-to-go',
  NEEDS_REVIEW: 'needs-review',
};

export const BOOKING_AGING_OPTIONS = {
  TODAY: 'today',
  ONE_WEEK: 'one-week',
  TWO_WEEKS: 'two-weeks',
  THREE_WEEKS: 'three-weeks',
  MORE_THAN_3_WEEKS: 'more-than-three-weeks',
};

export const BOOKING_NEEDS_REVIEW_OPTIONS = {
  YES: true,
  NO: false,
};

export const BOOKING_STATUS_OPTIONS = {
  OPTION: 'OPTION',
  CONFIRMED: 'CONFIRMED',
  FINAL: 'FINAL',
  PAST_DUE: 'PASTDUE',
  CANCELLED: 'CANCELLED',
};

export const BOOKING_SAIL_DATE_OPTIONS = {
  PAST: 'past-sail-date',
  FIFTEEN: '15-days-to-sail-date',
  FIFTEEN_FORTYFIVE: '15-to-45-days-to-sail-date',
  MORE_THAN_FORTYFIVE: 'more-than-45-days-to-sail',
};

export const BOOKING_HEADERS = {
  APPROVAL_STATUS: 'approval-status',
  STATUS: 'status',
  INVOICE: 'invoice',
  CURRENCY: 'currency',
  EMBARK: 'days-to-embarkation',
  REQ_DATE: 'req-date',
  AGE_DATE: 'age-date',
  NAME: 'name',
  ACCT_TYPE: 'acct-type',
  ACCT_NUM: 'acct-num',
  REFUND: 'total-refund-amt',
  PAID: 'paid-to-date',
  CANCEL: 'cancel-charges',
  GROSS: 'gross-outstanding',
  REVIEW: 'needs-review',
  COMMENTS: 'has-comments',
  Reason: 'system-reason',
  IN_USE: 'in-use',
};

export const APPROVAL_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED: 'declined',
};

export const APPROVAL_STATUS_FOR_EXPORT = {
  approved: 'Approved',
  pending: 'Pre-Approved',
  declined: 'Pending',
};

export const BOOKINGS_SORT_POSITION = {
  STATUS: 0,
  REVIEW: 1,
  COMMENTS: 2,
  INVOICE: 3,
  CURRENCY: 4,
  EMBARK: 5,
  REQ_DATE: 6,
  AGE_DATE: 7,
  NAME: 8,
  ACCT_TYPE: 9,
  ACCT_NUM: 10,
  REFUND: 11,
  PAID: 12,
  CANCEL: 13,
  GROSS: 14,
  Reason: 15,
  IN_USE: 16,
};

export const PAGE_NAME_FROM_PATH = {
  '/': 'Current_Refunds',
  '/errors': 'Errors',
  '/history': 'Historical_Records',
};

export const invoiceNumberModalAttrs = {
  'data-toggle': 'modal',
  'data-dismiss': 'modal',
  'data-target': '#bookingDetailsModal',
};

export const confirmSubmitModalAttrs = {
  'data-toggle': 'modal',
  'data-dismiss': 'modal',
  'data-target': '#confirmSubmitModal',
};

export const submitResponseModalAttrs = {
  'data-toggle': 'modal',
  'data-dismiss': 'modal',
  'data-target': '#submitResponseModal',
};

export const errorModalAttrs = {
  'data-toggle': 'modal',
  'data-dismiss': 'modal',
  'data-target': '#errorModal',
};

export const triggerModalAttrs = {
  'data-toggle': 'modal',
  'data-dismiss': 'modal',
  'data-target': '#confirmRefreshModal',
};

// ERRORS

export const ERROR_HEADERS = {
  RECORD_STATUS: 'status',
  INVOICE: 'invoice',
  CURRENCY: 'currency',
  SALES_CC_ID: 'sales-cc-id',
  PROCESSED_DATE: 'processed-date',
  PROCESSED_BY: 'processed-by',
  SOURCE: 'source',
  BOOKING_STATUS: 'booking-status',
  CRUISE_ID: 'cruise-id',
  SAIL_DATE: 'sail-date',
  NAME: 'name',
  ACCT_TYPE: 'acct-type',
  ACCT_NUM: 'acct-num',
  AMOUNT: 'Amount',
  MESSAGE: 'message',
};

export const ERROR_SORT_POSITION = {
  RECORD_STATUS: 0,
  INVOICE: 1,
  CURRENCY: 2,
  SALES_CC_ID: 3,
  PROCESSED_DATE: 4,
  PROCESSED_BY: 5,
  SOURCE: 6,
  BOOKING_STATUS: 7,
  CRUISE_ID: 8,
  SAIL_DATE: 9,
  NAME: 10,
  ACCT_TYPE: 11,
  ACCT_NUM: 12,
  AMOUNT: 13,
  MESSAGE: 14,
};

export const ERROR_PROCESSED_DATE_OPTIONS = {
  TODAY: 'today',
  LAST_WEEK: 'last-week',
  TWO_WEEKS: 'two-weeks',
  LAST_MONTH: 'last-month',
};

export const HISTORY_PROCESSED_DATE_OPTIONS = {
  TODAY: 'today',
  LAST_WEEK: 'last-week',
  TWO_WEEKS: 'two-weeks',
  LAST_MONTH: 'last-month',
  NA: 'n/a',
};

export const ERROR_HISTORY_STATUS_OPTIONS = {
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED',
};

export const PAYMENT_TYPE_OPTIONS = {
  DI: 'DI',
  AX: 'AX',
  VI: 'VI',
  VV: 'VV',
  MC: 'MC',
  ACH: 'ACH',
  CHECK: 'CHECK',
};

// HISTORY
export const HISTORY_HEADERS = {
  RECORD_STATUS: 'status',
  INVOICE: 'invoice',
  CURRENCY: 'currency',
  REQ_DATE: 'requested-date',
  PROCESSED_DATE: 'processed-date',
  AGE_DATE: 'aging-date',
  PROCESSED_BY: 'processed-by',
  ARA_PROCESSED: 'ara-processed',
  SOURCE: 'source',
  BOOKING_STATUS: 'booking-status',
  CRUISE_ID: 'cruise-id',
  SAIL_DATE: 'sail-date',
  NAME: 'name',
  ACCT_TYPE: 'acct-type',
  ACCT_NUM: 'acct-num',
  TOTAL_REFUND_AMOUNT: 'total-refund-Amount',
  REFUND_REASON: 'refund-reason',
};

export const HISTORY_SORT_POSITION = {
  RECORD_STATUS: 0,
  INVOICE: 1,
  CURRENCY: 2,
  REQ_DATE: 3,
  PROCESSED_DATE: 4,
  AGE_DATE: 5,
  PROCESSED_BY: 6,
  ARA_PROCESSED: 7,
  SOURCE: 8,
  BOOKING_STATUS: 9,
  CRUISE_ID: 10,
  SAIL_DATE: 11,
  NAME: 12,
  ACCT_TYPE: 13,
  ACCT_NUM: 14,
  TOTAL_REFUND_AMOUNT: 15,
  REFUND_REASON: 16,
};

export const REFUND_REASON_OPTIONS = {
  AIR_REFUND: 'AIR REFUND',
  BONUS_COMMISSION: 'BONUS COMMISSION',
  COMMISSION: 'COMMISSION',
  CRUISEFARE_REFUND: 'CRUISEFARE REFUND',
  CXL_BK_REFUND: 'CXL BK REFUND',
  CXL_BK_PAX_CXL_RFD: 'CXL BK/PAX CXL RFD',
  DECLINED_REFUND: 'DECLINED REFUND',
  EXTENSION_REFUND: 'EXTENSION REFUND',
  FOREIGN_TRANSFER_FEE: 'FOREIGN TRANSFER FEE',
  GIFT_REFUND: 'GIFT REFUND',
  HOTEL_REFUND: 'HOTEL REFUND',
  INSURANCE_REFUND: 'INSURANCE REFUND',
  OVERPAYMENT: 'OVERPAYMENT',
  PAYMENT_AGREEMENT: 'PAYMENT AGREEMENT',
  PAYMENT_CORRECTION: 'PAYMENT CORRECTION',
  PB_COMMISSION: 'PB COMMISSION',
  SHIP_BOARD_REFUND: 'SHIP BOARD REFUND',
  SHIPWIDE_COMP: 'SHIPWIDE COMP',
  SHOREX_REFUND: 'SHOREX REFUND',
  SITE_INSPECTION_CR: 'SITE INSPECTION CR',
  TC_REFUND: 'TC REFUND',
  TOUR_CONDUCTOR_RFD: 'TOUR CONDUCTOR RFD',
  TOUR_REFUND: 'TOUR REFUND',
  TRANSFER_REFUND: 'TRANSFER REFUND',
  TVL_PROTECTION_RFD: 'TVL PROTECTION RFD',
  VOUCHER_REFUND: 'VOUCHER REFUND',
};

// Refund Table Filters Options
export const readinessOptions = [
  { label: 'All', value: null },
  { label: 'Ready to Go', value: BOOKING_READINESS_OPTIONS.READY_TO_GO },
  { label: 'Needs Review', value: BOOKING_READINESS_OPTIONS.NEEDS_REVIEW },
];

export const typeOptions = [
  { label: 'All', value: null },
  { label: 'Direct', value: BOOKING_TYPE_OPTIONS.DIRECT },
  { label: 'TA', value: BOOKING_TYPE_OPTIONS.TA },
];

export const currenciesOptions = [
  { label: 'All', value: null },
  { label: 'All US office', value: ['USD', 'CAD'] },
  { label: 'USD', value: 'USD' },
  { label: 'CAD', value: 'CAD' },
  { label: 'AUD', value: 'AUD' },
];

export const sailDateOptions = [
  { label: 'Past Departure', value: BOOKING_SAIL_DATE_OPTIONS.PAST },
  {
    label: '< 15 Days to Departure',
    value: BOOKING_SAIL_DATE_OPTIONS.FIFTEEN,
  },
  {
    label: '15 - 45 Days to Departure',
    value: BOOKING_SAIL_DATE_OPTIONS.FIFTEEN_FORTYFIVE,
  },
  {
    label: '> 45 Days to Departure',
    value: BOOKING_SAIL_DATE_OPTIONS.MORE_THAN_FORTYFIVE,
  },
];

export const agingOptions = [
  { label: '< 24 Hours', value: BOOKING_AGING_OPTIONS.TODAY },
  { label: '24 hrs - 7 days', value: BOOKING_AGING_OPTIONS.ONE_WEEK },
  { label: '7 - 14 days', value: BOOKING_AGING_OPTIONS.TWO_WEEKS },
  { label: '14 - 21 days', value: BOOKING_AGING_OPTIONS.THREE_WEEKS },
  {
    label: '> 21 days',
    value: BOOKING_AGING_OPTIONS.MORE_THAN_3_WEEKS,
  },
];

export const needsReviewOptions = [
  { label: 'All', value: null },
  { label: 'Yes', value: BOOKING_NEEDS_REVIEW_OPTIONS.YES },
  { label: 'No', value: BOOKING_NEEDS_REVIEW_OPTIONS.NO },
];

export const statusOptions = [
  { label: 'Option', value: BOOKING_STATUS_OPTIONS.OPTION },
  { label: 'Confirmed ', value: BOOKING_STATUS_OPTIONS.CONFIRMED },
  { label: 'Final', value: BOOKING_STATUS_OPTIONS.FINAL },
  { label: 'Past Due', value: BOOKING_STATUS_OPTIONS.PAST_DUE },
  { label: 'Canceled', value: BOOKING_STATUS_OPTIONS.CANCELLED },
];

export const systemReasonOptions = [
  { label: 'Unsettled ACH', value: BOOKING_STATUS.UNSETTLED_ACH },
  { label: 'Unsettled CC', value: BOOKING_STATUS.UNSETTLED_CC },
  { label: 'Needs Supervisor Review', value: BOOKING_STATUS.NEEDS_SUP_REVIEW },
  { label: 'Check Commission', value: BOOKING_STATUS.CHECK_COMMISSION },
  { label: 'Check ECK Discount', value: BOOKING_STATUS.CHECK_ECK_DISCOUNT },
  { label: 'Cxl BK Unbalanced', value: BOOKING_STATUS.CXL_BOOKING_UNBALANCED },
  { label: 'Shortpay Allow', value: BOOKING_STATUS.SHORTPAY_ALLOW },
  {
    label: 'Different Payee Last Name',
    value: BOOKING_STATUS.DIFFERENT_LAST_NAME,
  },
  { label: 'Different Payee Address', value: BOOKING_STATUS.DIFFERENT_ADDRESS },
  { label: 'Pax Cxl Ext', value: BOOKING_STATUS.GUEST_CXL_EXTENSION },
  {
    label: 'Hold - MAR/APR per Executive',
    value: BOOKING_STATUS.HOLD_PER_EXEC,
  },
  {
    label: 'Hold - MAY/JUNE per Executive',
    value: BOOKING_STATUS.HOLD_PER_EXEC_MAY_JUNE,
  },
  {
    label: 'Hold - JULY/AUGUST per Executive',
    value: BOOKING_STATUS.HOLD_PER_EXEC_JULY_AUG,
  },
  { label: 'March / April Booking', value: BOOKING_STATUS.MARCH_APRIL },
  { label: 'May / June Booking', value: BOOKING_STATUS.MAY_JUNE },
  { label: 'July / August Booking', value: BOOKING_STATUS.JULY_AUG },
  { label: 'Other', value: BOOKING_STATUS.OTHER },
];

// Error/History Table Filters Options
export const errorProcessedDateOptions = [
  { label: 'Today', value: ERROR_PROCESSED_DATE_OPTIONS.TODAY },
  { label: 'Past 7 Days', value: ERROR_PROCESSED_DATE_OPTIONS.LAST_WEEK },
  { label: 'Past 8 - 14 Days', value: ERROR_PROCESSED_DATE_OPTIONS.TWO_WEEKS },
  {
    label: 'Past 15 - 30 Days',
    value: ERROR_PROCESSED_DATE_OPTIONS.LAST_MONTH,
  },
];

export const historyProcessedDateOptions = [
  { label: 'Today', value: HISTORY_PROCESSED_DATE_OPTIONS.TODAY },
  { label: 'Past 7 Days', value: HISTORY_PROCESSED_DATE_OPTIONS.LAST_WEEK },
  {
    label: 'Past 8 - 14 Days',
    value: HISTORY_PROCESSED_DATE_OPTIONS.TWO_WEEKS,
  },
  {
    label: 'Past 15 - 30 Days',
    value: HISTORY_PROCESSED_DATE_OPTIONS.LAST_MONTH,
  },
];

export const bookingStatusOptions = [
  { label: 'All', value: null },
  { label: 'Active', value: ERROR_HISTORY_STATUS_OPTIONS.ACTIVE },
  { label: 'Cancelled', value: ERROR_HISTORY_STATUS_OPTIONS.CANCELLED },
];

export const paymentTypeOptions = [
  { label: 'DI', value: PAYMENT_TYPE_OPTIONS.DI },
  { label: 'VI', value: PAYMENT_TYPE_OPTIONS.VI },
  { label: 'AX', value: PAYMENT_TYPE_OPTIONS.AX },
  { label: 'VV', value: PAYMENT_TYPE_OPTIONS.VV },
  { label: 'MC', value: PAYMENT_TYPE_OPTIONS.MC },
  { label: 'ACH', value: PAYMENT_TYPE_OPTIONS.ACH },
  { label: 'Check', value: PAYMENT_TYPE_OPTIONS.CHECK },
];

export const araProcessedOptions = [
  { label: 'All', value: null },
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const processedByOptions = records => [
  ...records.map(r => ({ label: r, value: r })),
];

export const refundReasonOptions = [
  { label: 'AIR REFUND', value: REFUND_REASON_OPTIONS.AIR_REFUND },
  {
    label: 'BONUS COMMISSION',
    value: REFUND_REASON_OPTIONS.BONUS_COMMISSION,
  },
  { label: 'COMMISSION', value: REFUND_REASON_OPTIONS.COMMISSION },
  {
    label: 'CRUISEFARE REFUND',
    value: REFUND_REASON_OPTIONS.CRUISEFARE_REFUND,
  },
  {
    label: 'CXL BK REFUND',
    value: REFUND_REASON_OPTIONS.CXL_BK_REFUND,
  },
  {
    label: 'CXL BK PAX CXL RFD',
    value: REFUND_REASON_OPTIONS.CXL_BK_PAX_CXL_RFD,
  },
  {
    label: 'DECLINED REFUND',
    value: REFUND_REASON_OPTIONS.DECLINED_REFUND,
  },
  {
    label: 'EXTENSION REFUND',
    value: REFUND_REASON_OPTIONS.EXTENSION_REFUND,
  },
  {
    label: 'FOREIGN TRANSFER FEE',
    value: REFUND_REASON_OPTIONS.FOREIGN_TRANSFER_FEE,
  },
  { label: 'GIFT REFUND', value: REFUND_REASON_OPTIONS.GIFT_REFUND },
  { label: 'HOTEL REFUND', value: REFUND_REASON_OPTIONS.HOTEL_REFUND },
  {
    label: 'INSURANCE REFUND',
    value: REFUND_REASON_OPTIONS.INSURANCE_REFUND,
  },
  { label: 'OVERPAYMENT', value: REFUND_REASON_OPTIONS.OVERPAYMENT },
  {
    label: 'PAYMENT AGREEMENT',
    value: REFUND_REASON_OPTIONS.PAYMENT_AGREEMENT,
  },
  {
    label: 'PAYMENT CORRECTION',
    value: REFUND_REASON_OPTIONS.PAYMENT_CORRECTION,
  },
  {
    label: 'PB COMMISSION',
    value: REFUND_REASON_OPTIONS.PB_COMMISSION,
  },
  {
    label: 'SHIP BOARD REFUND',
    value: REFUND_REASON_OPTIONS.SHIP_BOARD_REFUND,
  },
  {
    label: 'SHIPWIDE COMP',
    value: REFUND_REASON_OPTIONS.SHIPWIDE_COMP,
  },
  {
    label: 'SHOREX REFUND',
    value: REFUND_REASON_OPTIONS.SHOREX_REFUND,
  },
  {
    label: 'SITE INSPECTION_CR',
    value: REFUND_REASON_OPTIONS.SITE_INSPECTION_CR,
  },
  { label: 'TC REFUND', value: REFUND_REASON_OPTIONS.TC_REFUND },
  {
    label: 'TOUR CONDUCTOR_RFD',
    value: REFUND_REASON_OPTIONS.TOUR_CONDUCTOR_RFD,
  },
  { label: 'TOUR REFUND', value: REFUND_REASON_OPTIONS.TOUR_REFUND },
  {
    label: 'TRANSFER REFUND',
    value: REFUND_REASON_OPTIONS.TRANSFER_REFUND,
  },
  {
    label: 'TVL PROTECTION RFD',
    value: REFUND_REASON_OPTIONS.TVL_PROTECTION_RFD,
  },
  {
    label: 'VOUCHER REFUND',
    value: REFUND_REASON_OPTIONS.VOUCHER_REFUND,
  },
];

export const BOOKING_PROPERTIES = {
  REQ_DATE: 'RequestDate',
  FIRST_NAME: 'FirstName',
  LAST_NAME: 'LastName',
  ACCT_TYPE: 'AccountType',
  ACCT_NUM: 'AccountNum',
  DISC_AMT: 'DiscountAmount',
  REFUND_REASON: 'RefundReason',
  REQ_USER_ID: 'RequestUserId',
  UP_DATE: 'UpdateDate',
  REQ_STATUS: 'RequestStatus',
  REF_COMMENTS: 'RefundCommenst',
  AMT: 'Amount',
  TOTAL_RFD_AMT: 'TotalRefundAmount',
  REFUND_ID: 'RefundId',
  CCACHID: 'CCACHId',
  REF_TYPE: 'RefundType',
  METHOD: 'Method',
};
