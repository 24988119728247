import React from 'react';
import classNames from 'classnames';
import SelectDropdown, { createFilter } from 'react-select';

import FieldValidationError from './Components/FieldValidationError/FieldValidationError';
import PropTypes from './js/PropTypes';

import {
  DropdownIndicator,
  Option,
  SingleValue,
  ValueContainer,
} from './Components';

// import './Select.scss';

// disabled: Disable the select
// errorCodes: Errorcodes (Required/Invalid)
// errorMessages: Error Messages from Redux
// input: Redux form input props
// meta: state of this field that redux-form tracking
// options: Options to appear in select (value & label) pair
// placeholder: Placeholder label (example:*country)
const Select = ({
  disabled,
  errorCodes,
  errorMessages,
  helperLabel,
  input: { onBlur, onChange, onFocus, value },
  matchFrom,
  meta: { active, error, touched },
  options,
  placeholder,
  valueSeparator,
  hideError,
  title,
  dataId,
}) => {
  const showError = touched && !active && error;

  const selectedOption = options.find(option => option.value === value);

  return (
    <div
      className={classNames('select-section', {
        'error-snap': error,
        'validation-error': showError,
      })}
      title={title}
      data-id={dataId}
    >
      <SelectDropdown
        className={classNames('select-custom-control')}
        classNamePrefix="select"
        components={{ DropdownIndicator, Option, SingleValue, ValueContainer }}
        filterOption={createFilter({ matchFrom })}
        isDisabled={disabled}
        isSearchable={false}
        onBlur={() => {
          if (typeof onBlur === 'function') {
            onBlur();
          }
        }}
        onChange={option => {
          if (typeof onChange === 'function') {
            onChange(option);
          }
        }}
        blurInputOnSelect={false}
        onFocus={() => {
          if (typeof onFocus === 'function') {
            onFocus();
          }
        }}
        options={options}
        placeholder={placeholder}
        value={selectedOption}
        valueSeparator={valueSeparator}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
      />
      {showError && !hideError && (
        <FieldValidationError
          errorCode={error}
          errorCodes={errorCodes}
          errorMessages={errorMessages}
        />
      )}
    </div>
  );
};

Select.propTypes = {
  disabled: PropTypes.bool,
  errorCodes: PropTypes.shape({}),
  errorMessages: PropTypes.objectOf(PropTypes.string),
  helperLabel: PropTypes.string,
  input: PropTypes.shape({}),
  matchFrom: PropTypes.oneOf(['any', 'start']),
  meta: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  valueSeparator: PropTypes.string,
  hideError: PropTypes.bool,
  required: PropTypes.bool,
  title: PropTypes.string,
  dataId: PropTypes.string,
  value: PropTypes.array,
  closeMenuOnSelect: PropTypes.bool,
};

Select.defaultProps = {
  disabled: false,
  errorCodes: {},
  errorMessages: {},
  helperLabel: null,
  input: {},
  matchFrom: 'any',
  meta: {},
  isMulti: false,
  options: [],
  placeholder: null,
  valueSeparator: ' \u2013 ',
  hideError: false,
  required: false,
  title: '',
  dataId: undefined,
  value: [],
  closeMenuOnSelect: false,
};

export default Select;
